import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// import useDeviceInfo from '../useDeviceInfo';

export default () => {
  const [, setCookies, removeCookie] = useCookies(['token', 'username', 'rememberMe']);
  const navigate = useNavigate();
  const showLoginSucess = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    Toast.fire({
      title: '登入成功',
      icon: 'success',
      // confirmButtonText: '確定',
    });
  };
  // NOTE: return a function for login!
  return async ({ account, password, rememberMe }) => {
    // const { clientId, osVersion, deviceName } = useDeviceInfo();
    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_HOST}/api/auth/login`,
        method: 'post',
        // headers: {
        //   'Client-Type': 'Web',
        // },
        data: {
          // client_id: clientId,
          // os_version: osVersion,
          // device_name: deviceName,
          account,
          password,
          // device: 2,
        },
      });
      // console.log(res);
      if (res.status === 200) {
        if (rememberMe) {
          // if need to remember user, set a 30 days cookie
          setCookies('rememberMe', true, {
            path: '/',
            maxAge: 30 * 24 * 60 * 60 * 1000,
          });
          setCookies('account', account, { path: '/' });
        } else {
          // if user didn't check 'remember me' box, clear the cookie.
          // NOTE: this is the only way to clear username cookie expect being expired.
          removeCookie('rememberMe', { path: '/' });
        }
        setCookies('token', res.data.result.token, { path: '/' });
        setCookies('username', res.data.result.user_name, { path: '/' });
        setCookies('role', res.data.result.role, { path: '/' });
        navigate('/voucher/search');
        showLoginSucess();
        return res;
      }
      Swal.fire({
        title: '未預期的錯誤',
        text: '請稍後再試或者回報客服',
        icon: 'error',
        confirmButtonText: '確定',
      });
      return null;
    } catch (error) {
      console.log(error.response);
      Swal.fire({
        title: error.response.data,
        // text: ,
        icon: 'error',
        confirmButtonText: '確定',
      });
      return error.response;
    }
  };
};

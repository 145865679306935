// import useSWRtoGet from "../../useSWRtoGet";

export default ({
  startDate,
  endDate,
  quantity,
  atrBuilding,
  status,
  city,
  roomCode,
  voucherCode,
  atrManagement,
  voucherId
}) => {
  const url = `${
    process.env.REACT_APP_API_HOST
  }/api/voucher/donw_load_vouchers?${
    startDate ? `start_date=${startDate}&` : ""
  }${endDate ? `end_date=${endDate}&` : ""}page_index=1&page_size=${
    quantity || "10"
  }&atr_building=${atrBuilding || "一般宅"}&status=${status || "未送鼎新"}${
    city && city !== "全部" ? `&city=${city}` : ""
  }${roomCode ? `&room_code=${roomCode}` : ""}${
    voucherCode ? `&voucher_code=${voucherCode}` : ""
  }${
    atrManagement && atrManagement !== "全部"
      ? `&atr_management=${atrManagement}`
      : ""
  }${voucherId ? `&voucher_id=${voucherId.join(",")}` : ""}`;
  // const { data, error, isLoading } = useSWRtoGet(url);
  return {
    downloadVouchersUrl: url
  };
};

/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import Checkbox from "components/input/CheckboxMock";
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from "react";
import styled from "styled-components";
import { palette } from "../../style/palette";
import TableRow from "./TableRow";

const RawTable = forwardRef(
  (
    {
      objects,
      order,
      checkbox,
      emitCheckboxList,
      cancelAllCollapse,
      customColumnTitleBefore,
      customColumnBefore,
      customColumnAfter,
      pointer,
      className
    },
    ref
  ) => {
    const refs = useRef({});
    const [checkboxesList, setCheckboxesList] = useState([]);
    const [allCheckboxesAreChecked, setAllCheckboxesAreChecked] =
      useState(false);

    useImperativeHandle(ref, () => ({
      uncheckAllCheckboxes() {
        const { length } = Object.keys(refs.current);
        for (let i = 0; i < length; i += 1) {
          const dom = refs.current[i];
          dom.uncheck();
        }
      },
      collapseAllFeeList() {
        const { length } = Object.keys(refs.current);
        for (let i = 0; i < length; i += 1) {
          const dom = refs.current[i];
          dom.collapseFeeList();
        }
      }
    }));

    const getAllChecked = () => {
      // get all boxes which is checked
      const checkedCheckboxes = [];
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((eachCheckbox, index) => {
        if (eachCheckbox.checked && index !== 0) {
          // 0 = check all
          const objectIndex = index - 1;
          // not include the first checkbox, because it's 'check all'
          checkedCheckboxes.push(objectIndex);
        }
      });
      return checkedCheckboxes;
    };

    const changeAllCheckboxes = (result) => {
      const { length } = Object.keys(refs.current);
      for (let i = 0; i < length; i += 1) {
        const dom = refs.current[i];
        // eslint-disable-next-line no-continue
        if (!dom) continue;
        if (result) dom.check();
        else dom.uncheck();
      }
    };
    const checkIfAllCheckboxesAreChecked = () => {
      let allChecked = true;
      const checkboxes = document.querySelectorAll('input[type="checkbox"]');
      if (checkboxes.length === 0) return false;
      checkboxes.forEach((eachCheckbox, index) => {
        if (index === 0) return;
        if (!eachCheckbox.checked) allChecked = false;
      });
      return allChecked;
    };

    useEffect(() => {
      // when checkboxes list changed, emit the result
      if (emitCheckboxList) emitCheckboxList(checkboxesList); // temp
      // when click checkbox, check if all checked
      setAllCheckboxesAreChecked(checkIfAllCheckboxesAreChecked());
    }, [checkboxesList, objects]);

    return (
      <div className={className}>
        {objects && objects?.length !== 0 ? (
          <div className="rTable">
            <div className="rTableHeading">
              {checkbox && (
                <div
                  className="rTableHead"
                  style={{ padding: "0 15px 0 0", width: "0px" }}
                  key="unbind"
                >
                  <Checkbox
                    checked={allCheckboxesAreChecked}
                    onChange={async (e) => {
                      await changeAllCheckboxes(e.target.checked);
                      setCheckboxesList(getAllChecked());
                    }}
                  />
                  全選
                </div>
              )}
              {customColumnTitleBefore && (
                <div className="rTableHead" key="unbind">
                  {customColumnTitleBefore}
                </div>
              )}
              {objects[0] &&
                order.map((item) => (
                  <div className="rTableHead" key={item.title}>
                    {item.title}
                  </div>
                ))}
            </div>

            {objects.map((object, index) => (
              <TableRow
                // eslint-disable-next-line no-return-assign
                ref={(element) => (refs.current[index] = element)}
                object={object}
                order={order}
                checkbox={checkbox}
                onClickCheckbox={() => setCheckboxesList(getAllChecked())}
                cancelAllCollapse={cancelAllCollapse}
                customColumnBefore={customColumnBefore}
                customColumnAfter={customColumnAfter}
                pointer={pointer}
                key={index}
                // allChecked={allChecked}
              />
            ))}
          </div>
        ) : (
          <div className="loadingOrEmpty flex items-center justify-center flex-col">
            {objects?.length === 0 ? (
              <>
                {/* <img
                className="w-52 mb-4"
                src="/images/icons/nocontent.svg"
                alt="no content"
              /> */}
                查無任何資料
              </>
            ) : (
              "讀取中⋯⋯"
            )}
          </div>
        )}
      </div>
    );
  }
);

const Table = styled(RawTable)`
  min-height: 500px;
  .rTable {
    display: table;
    border-collapse: collapse;
    width: 100%;
    margin: 15px 0;
    color: #292929;
    text-align: left;
    white-space: nowrap;

    .rTableHeading {
      display: table-header-group;
      color: ${palette.darkerGray};
      border-bottom: 2px ${palette.gray} solid;
      line-height: 35px;
      font-size: 16px;
      letter-spacing: 0.8px;
      @media screen and (max-width: 935px) {
        height: 40px;
        line-height: 40px;
      }
      &:nth-child(1) {
        background: transparent;
      }
    }

    .rTableHead {
      display: table-cell;
      white-space: nowrap;
      padding: 10px 0 10px 15px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.7px;
    }

    .rTableRow {
      display: table-row;
      line-height: 40px;
      font-size: 16px;
      letter-spacing: 0.8px;

      &:hover {
        /* background: rgba(0, 0, 0, 0.02); */
      }

      .rTableCell {
        padding: 10px 0 10px 15px;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.7px;
        /* white-space: pre-wrap; */
        /* position: relative; */

        .btn {
          margin: 8px 5px;
        }
      }

      .nowrap {
        white-space: nowrap;
      }

      &:nth-child(odd) {
        /* background: rgba(0, 0, 0, 0.03); */
        /* background: #edf2f7; */
      }
    }

    .rTableRow {
      border-bottom: 2px ${palette.gray} solid;

      position: relative;

      &:hover {
        background-color: rgba(0, 0, 0, 0.02);
      }
    }

    .activeTr {
      border-bottom: 300px solid transparent;

      .fees {
        display: block;
      }
    }
  }

  .rTableCell {
    display: table-cell;
  }

  .loadingOrEmpty {
    min-height: 400px;
    font-size: 1.4rem;
    color: #999;
    font-weight: bold;
  }
`;

export default Table;

import React from 'react';
// import FirstResetPassword from 'pages/FirstResetPassword';
import LogIn from 'pages/Login';
// import ForgotPassword from 'pages/ForgotPassword';

const publicRoutes = [
  {
    path: '/login',
    component: <LogIn />,
    exact: true,
    backUrl: '/',
  },
  // {
  //   path: '/forgot-password',
  //   component: <ForgotPassword />,
  //   exact: true,
  //   backUrl: '/',
  // },
  // {
  //   path: '/first-login-reset-password',
  //   component: <FirstResetPassword />,
  //   exact: true,
  //   backUrl: '/',
  // },
];

export default publicRoutes;

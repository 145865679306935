/* eslint-disable react/prop-types */
import React, { memo, useEffect } from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import useLogout from "hooks/api/useLogout";
import { useCookies } from "react-cookie";
// import useNotifications from 'hooks/api/notification/useNotifications';
import { palette } from "../style/palette";
// import Image from "./Image";

function RawHeader({ isSidebarCollapsed, title, className }) {
  // , setSidebarCollapsed
  const logout = useLogout();
  // const { notifications } = useNotifications({ pageIndex: 1, pageSize: 5 });
  const [cookies, setCookies] = useCookies(["username"]);
  // const toggleSidebar = () => {
  //   // note that these are not boolean but string! cookie can only save string
  //   if (isSidebarCollapsed) setSidebarCollapsed(false);
  //   else setSidebarCollapsed(true);
  //   // else console.log('there are some problem in sidebar collapsed!');
  // };
  useEffect(() => {
    // note that cookie only save string, no boolean
    setCookies("collapsed", isSidebarCollapsed, { path: "/" });
  }, [isSidebarCollapsed]);
  return (
    <nav className={className}>
      {/* <button onClick={toggleSidebar} type="button" className="toggleButton">
        <Image
          className={isSidebarCollapsed ? "reverse" : ""}
          src="/images/icons/hide.png"
          alt="toggle button"
        />
      </button> */}
      <h1 className="pageTopic">{title}</h1>
      <div className="rightArea">
        {/* <div className="notification">
          <span className="notification text-6xl" />
          <div className="notificationDropdown">
            {notifications?.map((notification) => (
              <div className="notificationItem">{notification.message}</div>
            ))}
          </div>
        </div> */}
        <div className="accountStatus">
          {/* <div className="profilePhoto" /> */}
          {cookies.username}
          <div className="arrowDown" />
          <div className="accountDropdown">
            {/* <Link to="/setting/edit-account">
              <li>
                <span className="setting text-4xl" />
                帳號設定
              </li>
            </Link>
            <Link to="/setting/edit-password">
              <li>
                <span className="password text-4xl" />
                變更密碼
              </li>
            </Link> */}
            <button type="button" onClick={() => logout()}>
              <li>
                <span className="log_out text-4xl" />
                登出
              </li>
            </button>
          </div>
        </div>
      </div>
      <div className="borderBottom" />
    </nav>
  );
}

RawHeader.propTypes = {
  className: PropTypes.string
};

RawHeader.defaultProps = {
  className: ""
};

const Header = styled(memo(RawHeader))`
  position: sticky;
  /* background: ${palette.mainColor}; */
  /* background: ${palette.blue}; */
  /* background: white; */
  height: 6rem;
  z-index: 1000;
  /* color: white; */
  color: ${palette.black};
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 300;
  padding: 0 3.3rem;

  .pageTopic {
    position: relative;
    font-size: 2rem;
    top: 1.6rem;
    left: 0.5rem;
  }

  .toggleButton {
    position: relative;
    width: 3rem;
    height: 40px;
    top: 6px;
    padding-left: 21px;
    margin-right: 10px;
    display: inline-flex;
    align-content: center;
    justify-content: center;

    span {
      position: absolute;
      width: 100%;
      transition-duration: 0.2s;
    }
    /* span + span {
      opacity: 0;
      color: ${palette.salmon};
    }  */
    /* &:hover {
      span {
        opacity: 0;
      }
      span + span {
        opacity: 1;
      }
    } */
    .reverse {
      transform: scaleX(-1);
    }
  }

  .rightArea {
    position: absolute;
    right: 10px;
    top: 0px;
    align-items: center;
    display: flex;
    z-index: 1;

    .notification {
      position: relative;
      margin-right: 0px;
      height: 62px;
      padding-top: 13px;
      cursor: pointer;
      color: ${palette.darkestGray};

      &:hover {
        background: ${palette.lightGray};
        .notificationDropdown {
          display: block;
        }
      }

      .notificationDropdown {
        position: absolute;
        right: 0;
        top: 100%;
        width: calc(100% - 2px); // border
        background: white;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px ${palette.gray};
        display: none;

        .notificationItem {
          padding: 10px 20px;
          display: flex;
          align-items: center;
          font-size: 14px;
          border-bottom: ${palette.lightGray} 1px solid;
          cursor: pointer;
        }
      }
    }
    .accountStatus {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 700;
      padding: 2rem 3rem;
      /* padding-right: 22px; */
      cursor: pointer;

      .profilePhoto {
        width: 32px;
        height: 32px;
        background-color: ${palette.warningRed};
        border-radius: 50%;
        margin-right: 10px;
      }
      .arrowDown {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 7px solid ${palette.darkerGray};
        margin-left: 10px;
      }

      .accountDropdown {
        position: absolute;
        right: 2.5rem;
        top: calc(100% - 2px);
        width: calc(100% - 2px); // border
        background: white;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: solid 1px ${palette.gray};
        display: none;

        a,
        button {
          color: ${palette.black};
          text-decoration: none;
          background: transparent;
          border: 0;
          width: 100%;
          padding: 0;
          font-weight: 800;
        }

        li {
          list-style: none;
          padding: 10px 12px;
          display: flex;
          align-items: center;
          font-size: 14px;
          border-bottom: ${palette.lightGray} 1px solid;
          cursor: pointer;

          span {
            /* width: 17.3px; */
            margin-right: 6px;
          }

          .settingImg {
            width: 24.3px;
            margin-right: 6px;
            margin-left: -3px;
          }

          &:hover {
            background: ${palette.salmon};
            color: white;

            /* img {
              filter: brightness(100);
            } */
          }
        }
      }

      &:hover {
        background: ${palette.lightGray};
        .accountDropdown {
          display: block;
        }
      }
    }
  }

  .borderBottom {
    position: absolute;
    bottom: 0;
    width: calc(100% - 6.6rem);
    /* left: 10%; */
    height: 2px;
    background: ${palette.gray};
  }
`;

export default memo(Header);

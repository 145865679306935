// import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default () => {
  const [, setCookies, removeCookie] = useCookies([
    "token",
    "username",
    "rememberMe",
  ]);
  const navigate = useNavigate();

  const showLoginSucess = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      title: "登入成功",
      icon: "success",
      // confirmButtonText: '確定',
    });
  };
  // NOTE: return a function for login!
  return () => {
    setCookies("username", "developer", {
      path: "/",
      maxAge: 30 * 24 * 60 * 60 * 1000,
    });
    setCookies("token", "test", { path: "/" });
    navigate("/");
    showLoginSucess();
    return { status: 200 };
  };
};

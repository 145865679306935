/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import inputStyle from '../../style/form/input';

function RawInputMock({
  className, fontSize, isblock, iserror, placeholder, title, size = 30, ...props
}) {
  return (
    <input
      className={className}
      placeholder={placeholder || `請輸入${title}`}
      size={size}
      {...props}
    />
  );
}

const InputMock = styled(RawInputMock)`
  ${inputStyle};
  ${(props) => (props.disabled
    ? `
    background: transparent;
  `
    : '')}
`;

export default InputMock;

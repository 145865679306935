import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import LoginLayout from "../components/LoginLayout";
import Button from "../components/button";
import { palette } from "../style/palette";

function RawNotFound({ className }) {
  return (
    <LoginLayout>
      <div className={className}>
        <div className="titleGrp" style={{ marginBottom: "2rem" }}>
          <div className="title">YFS</div>
        </div>
        {/* <img className="cones" src="/images/404.png" alt="404 not found" /> */}
        <div className="redText">404 Not Found</div>
        您可點選下方按鈕回首頁
        <br />
        <br />
        <Button className="btn" isBlock to="/">
          回首頁
        </Button>
      </div>
    </LoginLayout>
  );
}

RawNotFound.propTypes = {
  className: PropTypes.string
};

RawNotFound.defaultProps = {
  className: ""
};

const NotFound = styled(RawNotFound)`
  text-align: center;
  .cones {
    display: inline-block;
    width: 160px;
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .redText {
    font-size: 24px;
    color: ${palette.warningRed};
    margin-bottom: 12px;
  }
  .btn {
    margin-top: 15px;
  }
`;

export default NotFound;

import Button from "components/button";
import React from "react";
import { palette } from "style/palette";
import styled from "styled-components";

function RawTabs({ className, active, options, onClick }) {
  return (
    <div className={className}>
      {options.map((option) => (
        <Button
          className={`tab${active === option ? " active" : ""}`}
          little
          onClick={() => onClick(option)}
          key={option}
        >
          {option}
        </Button>
      ))}
    </div>
  );
}

const Tabs = styled(RawTabs)`
  display: inline-block;
  margin-right: 3rem;
  /* vertical-align: -1.5rem; */
  .tab {
    background: transparent;
    border-color: ${palette.darkGray};
    border-radius: 0px;
    margin-right: 0;
    margin-left: -1px;
    top: 7px;
    color: ${palette.darkerGray};
    opacity: 0.7;
  }

  .active {
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1;
  }

  .tab:first-child {
    margin-left: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .tab:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export default Tabs;

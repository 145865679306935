/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { palette } from "../../style/palette";
import Category from "./Category";
import sitemap from "../../sitemap";

function RawSidebar({ sidebarCollapsed, setSidebarCollapsed, className }) {
  const [cookies, setCookies] = useCookies(["lockSidebar"]);
  const [activeCategories, setActiveCategories] = useState([]);
  const [lock, setLock] = useState(cookies.lockSidebar ? cookies.lockSidebar !== "false" : false);
  const location = useLocation();
  useLayoutEffect(() => {
    const nowPath = location.pathname.split("/")[1];
    setActiveCategories([nowPath]);
  }, [location]);
  // eslint-disable-next-line max-len
  const isActiveCategory = (nowPath) =>
    activeCategories.find((categoryPath) => categoryPath === nowPath) ===
    nowPath;
  // const handleClickCategory = (nowPath) => {
  // if (!isActiveCategory(nowPath)) setActiveCategories([...activeCategories, nowPath]);
  // else setActiveCategories(activeCategories.filter((category) => category !== nowPath));
  // };

  return (
    <div
      className={className}
      style={sidebarCollapsed ? { flexBasis: "50px" } : { minWidth: "200px" }}
      onMouseEnter={() => {
        if (!lock) setSidebarCollapsed(false);
      }}
      onMouseLeave={() => {
        if (!lock) setSidebarCollapsed(true);
      }}
    >
      <h1
        className="logoLink"
        style={sidebarCollapsed ? { letterSpacing: "2rem" } : {}}
      >
        <Link
          to="/voucher/search"
          style={sidebarCollapsed ? { marginLeft: "2rem" } : {}}
        >
          {/* <Image src="/images/logo.png" className="logo icon" alt="logo" /> */}
          YFS
        </Link>
      </h1>
      <h2>
        <i style={sidebarCollapsed ? { opacity: "0" } : {}}>昱鈊財務系統</i>
      </h2>
      {sitemap.map((category) =>
        category.path !== "setting" ? (
          // note: setting category not exist in sidebar but in header dropdown
          <Category
            key={category.path}
            category={category}
            active={isActiveCategory(category.path)}
            // onClick={() => handleClickCategory(category.path)}
            title={category.title}
            sidebarCollapsed={sidebarCollapsed}
          />
        ) : null
      )}
      <div
        className="lockBtn"
        onClick={() => {
          setSidebarCollapsed(!lock);
          setLock(!lock);
          setCookies("lockSidebar", !lock, { path: "/" });
        }}
        role="button"
        tabIndex={0}
      >
        {lock ? (
          <img src="/images/icons/lock.png" className="lockImg" alt="lock" />
        ) : (
          <img
            src="/images/icons/unlock.png"
            className="lockImg"
            alt="unlock"
          />
        )}
      </div>
    </div>
  );
}

RawSidebar.propTypes = {
  className: PropTypes.string,
  sidebarCollapsed: PropTypes.bool
};

RawSidebar.defaultProps = {
  className: "",
  sidebarCollapsed: false
};

const Sidebar = styled(memo(RawSidebar))`
  .lockBtn {
    position: absolute;
    bottom: 30px;
    left: 17px;
    width: 17px;
    cursor: pointer;

    .lockImg {
      width: 100%;
    }
  }

  display: inline-block;
  vertical-align: top;
  position: sticky;
  height: 100vh;
  max-height: 1500px;
  min-width: 50px;
  overflow: hidden; // there is no dropdown now
  flex-basis: 200px;
  /* min-width: 230px; */
  font-size: 13px;
  left: 0;
  top: 0;
  /* border-top: 1px solid ${palette.gray}; */
  /* overflow-y: scroll; */
  /* box-shadow: 0 0 10px 0 #ddd; */
  z-index: 999;
  /* min-width: 0; */
  background: rgb(250, 250, 250);

  .logoLink {
    width: 100%;
    margin-top: 1.5rem;
    /* display: inline-flex;
    align-items: center;
    justify-content: flex-end; */
    vertical-align: top;
    /* width: 8.2rem; */
    padding-top: 2.5rem;
    margin-bottom: 0.3rem;
    color: ${palette.black};
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    text-decoration: none;
    overflow: hidden;
    transition-property: width, letter-spacing;
    transition-duration: 0.4s;
    font-style: italic;

    a {
      margin-left: 4rem;
      transition-property: margin;
      transition-duration: 0.4s;
    }
  }

  h2 {
    width: 100%;
    overflow: hidden;
    margin-bottom: 2.5rem;
    i {
      margin-left: 4rem;
      color: rgb(149, 164, 178);
      word-break: keep-all;
      transition-property: opacity;
      transition-duration: 0.4s;
    }
  }

  transition-property: flex-basis, min-width;
  transition-duration: 0.4s;
`;

export default memo(Sidebar);

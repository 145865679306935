/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import Table from "components/table";
import WhitePaper from "components/WhitePaper";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Layout from "components/FormLayout";
import InputDateRange from "components/input/InputDateRange";
import Select from "components/input/Select";
import Button from "components/button";
import Form from "components/Form";
import Input from "components/input";
import Tabs from "components/input/Tabs";
import useVouchers from "hooks/api/voucher/useVouchers";
import useSendingVoucherToErp from "hooks/api/voucher/useSendingVoucherToErp";
import useDownloadVouchers from "hooks/api/voucher/useDownloadVouchers";
import Swal from "sweetalert2";
import { palette } from "style/palette";
import { useSearchParams } from "react-router-dom";
import useConfig from "hooks/api/useConfig";
import useDeleteVouchers from "hooks/api/voucher/useDeleteVouchers";

function RawVoucherSearch({ className }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tableRef = useRef(null);
  const config = useConfig();

  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const [startDate, setStartDate] = useState(
    sevenDaysAgo.toISOString().split("T")[0].split("-").join("/")
  ); // yyyy/mm/dd
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0].split("-").join("/")
  ); // yyyy/mm/dd
  const pageSize = 300; // 300 each load
  const [quantity, setQuantity] = useState(pageSize);
  const [atrManagement, setAtrManagement] = useState("包租");
  const [city, setCity] = useState("臺北市");
  const [roomCode, setRoomCode] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [status, setStatus] = useState("未送鼎新");
  const [target, setTarget] = useState("");
  const [group, setGroup] = useState("");
  const [activeTab, setActiveTab] = useState(
    searchParams.get("atr_building") === "social" ? "社宅" : "一般宅"
  );
  const [scrollHint, setScrollHint] = useState(""); // if it can scroll to load more, show this
  const [checkboxesList, setCheckboxesList] = useState([]);
  const { vouchers, totalCount, resendAPI } = useVouchers({
    startDate,
    endDate,
    quantity,
    atrManagement,
    city,
    roomCode,
    voucherCode,
    status,
    atrBuilding: activeTab,
    target,
    group
  });

  const [checkedVoucherIdList, setCheckedVoucherIdList] = useState([]);
  const [amount, setAmount] = useState(0);
  const getAmount = () => {
    let total = 0;
    // console.log(checkboxesList);
    for (let i = 0; i < checkboxesList.length; i += 1) {
      const index = checkboxesList[i];
      if (vouchers[index]) total += vouchers[index].amount;
    }
    return total;
  };
  useEffect(() => {
    // temp
    // every time checklist changed, update id list
    // note that checkboxesList contains index, voucherList contains id
    // no matter erp or export, it should pass id list
    if (vouchers) {
      const voucherIdArray = [];
      for (let i = 0; i < checkboxesList.length; i += 1) {
        const checkboxIndex = checkboxesList[i];
        if (vouchers[checkboxIndex])
          voucherIdArray.push(vouchers[checkboxIndex].voucher_id);
      }
      setCheckedVoucherIdList(voucherIdArray);
      setAmount(getAmount());
    }
  }, [checkboxesList, vouchers]);

  const [ableToSendErp, setAbleToSendErp] = useState(true);
  const [vouchersOnPage, setVouchersOnPage] = useState(null);
  useEffect(() => {
    if (vouchers) {
      setAbleToSendErp(status === "未送鼎新" || status === "被退回"); // control button with state
      setVouchersOnPage(vouchers); // manage the period from clicking filter to showing
    }
  }, [vouchers]);

  const sendingVoucherToErp = useSendingVoucherToErp();
  const clickSendVoucher = async () => {
    // send to erp btn
    if (checkedVoucherIdList.length === 0) {
      Swal.fire({
        title: "沒有勾選單據",
        text: "請勾選匯入單後再點選按鈕",
        icon: "warning",
        showCloseButton: true,
        confirmButtonText: "確定"
      });
      return;
    }
    Swal.fire({
      title: "即將送鼎新立帳",
      html: `總筆數: ${checkedVoucherIdList.length} 筆<br>總金額: ${amount} 元`,
      icon: "question",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: "確定",
      denyButtonText: "取消"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await sendingVoucherToErp(checkedVoucherIdList, amount);
        if (res.status === 200) {
          resendAPI();
          tableRef.current.uncheckAllCheckboxes();
          setCheckboxesList([]); // note: after sending, we should clear list to prevent catch null
        }
      }
    });
  };

  const { downloadVouchersUrl } = useDownloadVouchers({
    startDate,
    endDate,
    quantity,
    atrManagement,
    city,
    roomCode,
    voucherCode,
    status,
    atrBuilding: activeTab,
    voucherId: checkedVoucherIdList
  });
  const clickExport = () => {
    // export btn
    if (checkedVoucherIdList.length === 0) {
      Swal.fire({
        title: "請先勾選單據",
        html: "當前勾選單據為 0 筆",
        icon: "warning",
        showCloseButton: true,
        confirmButtonText: "確定"
      });
    } else {
      window.open(downloadVouchersUrl, "_blank", "noopener,noreferrer");
    }
  };

  const deleteVoucher = useDeleteVouchers();
  const clickDelete = () => {
    // delete btn
    if (checkedVoucherIdList.length === 0) {
      Swal.fire({
        title: "請先勾選單據",
        html: "當前勾選單據為 0 筆",
        icon: "warning",
        showCloseButton: true,
        confirmButtonText: "確定"
      });
    } else {
      Swal.fire({
        title: "即將刪除匯入單",
        html: `總筆數: ${checkedVoucherIdList.length} 筆`,
        icon: "question",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "確定",
        denyButtonText: "取消"
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await deleteVoucher(checkedVoucherIdList)
          if (res.status === 200) {
            resendAPI();
            tableRef.current.uncheckAllCheckboxes();
            setCheckboxesList([]); // note: after deleting, we should clear list to prevent catch null
          }
        }
      });
    }
  };

  const scrollAndLoad = () => {
    // onScroll in layout
    const scrollDom = document.getElementsByClassName("scrollBox")[0];
    const contentDom = document.getElementsByClassName("contentBox")[0];
    const scrollMax = contentDom.offsetHeight - scrollDom.offsetHeight;
    const allowRange = 10;
    if (scrollDom.scrollTop >= scrollMax - allowRange) {
      if (quantity < totalCount) {
        setQuantity(quantity + pageSize);
        setScrollHint("等待載入中......");
      } else {
        setScrollHint("已經沒有更多單據了");
      }
    }
  };
  return (
    <Layout breadcrumbs={false} title="匯入單查詢" onScroll={scrollAndLoad}>
      <div className={className}>
        <Form
          initialValues={{
            date: [startDate, endDate],
            atrManagement,
            city,
            status,
            roomCode,
            voucherCode
          }}
          validate={() => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values) => {
            setStartDate(values.date[0]);
            setEndDate(values.date[1]);
            setCity(values.city);
            setStatus(values.status);
            setAtrManagement(values.atrManagement);
            setRoomCode(values.roomCode);
            setVoucherCode(values.voucherCode);
            setQuantity(pageSize); // recover to initial page size
            setTarget(values.target);
            setGroup(values.group);
            tableRef.current.uncheckAllCheckboxes();
            tableRef.current.collapseAllFeeList();
            resendAPI();
          }}
        >
          <div className="upperArea">
            <WhitePaper className="voucherFilter">
              <Tabs
                className="tabs"
                active={activeTab}
                options={["一般宅", "社宅"]}
                onClick={(item) => {
                  setActiveTab(item);
                  if (item === "社宅") {
                    setSearchParams({
                      atr_building: "social"
                    });
                  } else if (searchParams.has("atr_building")) {
                    searchParams.delete("atr_building");
                    setSearchParams(searchParams);
                  }
                }}
              />
              <Select
                name="atrManagement"
                title="物件屬性"
                placeholder="全部"
                options={[
                  {
                    value: "包租",
                    text: "包租"
                  },
                  {
                    value: "代管",
                    text: "代管"
                  }
                ]}
              />
              <Select
                name="city"
                title="縣市"
                placeholder="全部"
                options={config?.cities?.map((eachCity) => ({
                  value: eachCity,
                  text: eachCity
                }))}
              />
              <Select
                name="status"
                title="狀態"
                // lineUp
                required
                hideStarSign
                options={[
                  {
                    value: "未送鼎新",
                    text: "未送鼎新"
                  },
                  {
                    value: "處理中",
                    text: "處理中"
                  },
                  {
                    value: "鼎新已接收",
                    text: "鼎新已接收"
                  },
                  {
                    value: "立帳成功",
                    text: "立帳成功"
                  },
                  {
                    value: "被退回",
                    text: "被退回"
                  },
                  {
                    value: "作廢",
                    text: "作廢"
                  }
                ]}
              />
              <Select
                name="group"
                title="單別"
                placeholder="全部"
                options={config?.groups?.map((group) => ({
                  value: group,
                  text: group
                }))}
                // lineUp
              />
              <Input
                name="roomCode"
                title="房代碼"
                placeholder="房代碼"
                size="9"
              />
              <Input
                name="voucherCode"
                title="匯入單號"
                placeholder="匯入單號"
                size="12"
                // lineUp
              />
              <Select
                name="target"
                title="費用對象"
                placeholder="全部"
                options={[
                  {
                    value: "房東",
                    text: "房東"
                  },
                  {
                    value: "房客",
                    text: "房客"
                  }
                ]}
              />
              <InputDateRange
                name="date"
                title="圈帳日期"
                placeholder="選擇日期區間"
                required
                hideStarSign
                // size="27"
              />
              <Button className="voucherFilterBtn" type="submit" little>
                查詢
              </Button>
            </WhitePaper>

            <Button
              className="sendBtn"
              onClick={clickSendVoucher}
              huge
              disabled={!ableToSendErp}
            >
              <a className="bigBtnContent">
                <img src="/images/icons/paper_plane.png" alt="paper plane" />
                <span>
                  送鼎新立帳
                  <br />( 根據勾選單據 )
                </span>
              </a>
            </Button>
          </div>
        </Form>
        <br />
        <div className="numberHint">
          （本次查詢的匯入單共有 {totalCount} 筆，已勾選{" "}
          {checkedVoucherIdList.length} 筆，總金額 {amount} 元）
        </div>
        <a className="excelBtn" onClick={() => clickExport()}>
          <img src="/images/icons/export.png" alt="download" />
          匯出費用明細 ( 根據勾選單據 )
        </a>
        <a className="excelBtn" onClick={() => clickDelete()} style={{marginLeft: "20px"}}>
          {/* <img src="/images/icons/export.png" alt="paper plane" /> */}
          刪除匯入單 ( 根據勾選單據 )
        </a>
        <Table
          ref={tableRef}
          objects={vouchersOnPage}
          checkbox
          emitCheckboxList={setCheckboxesList}
          pointer
          customColumnAfter={(item) =>
            status === "未送鼎新" ? (
              <Button
                className="collapsedBtn"
                to={`/voucher/${item.voucher_id}`}
                mini
                targetBlank
              >
                編輯
              </Button>
            ) : null
          }
          order={[
            {
              key: "voucher_code",
              title: "匯入單號"
            },
            {
              key: "room_code",
              title: "房代碼"
            },
            {
              key: "target",
              title: "費用對象"
            },
            {
              key: "fee_owner",
              title: "姓名"
            },
            {
              key: "amount",
              title: "總額"
            },
            {
              key: "group_name",
              title: "單別"
            },
            {
              key: "created",
              title: "圈帳日期",
              format: (item) => item.created.split("T")[0]
            },
            {
              key: "status_forwarded",
              title: "狀態",
              format: (item) => {
                if (item.status_forwarded === 1) return "未送鼎新";
                if (item.status_forwarded === 2) return "處理中";
                if (item.status_forwarded === 3) return "鼎新已接收";
                if (item.status_forwarded === 4) return "立帳成功";
                if (item.status_forwarded === 5) return "被退回";
                if (item.status_forwarded === 6) return "作廢";
                return "";
              }
            }
          ]}
        />
        {vouchers?.length !== 0 ? (
          <div className="scrollHint">{scrollHint}</div>
        ) : null}
      </div>
    </Layout>
  );
}

const VoucherSearch = styled(RawVoucherSearch)`
  .upperArea {
    white-space: nowrap;
  }
  .scrollHint {
    text-align: center;
    margin-top: 5rem;
    padding-bottom: 10rem;
  }
  .numberHint {
    position: absolute;
    right: 5rem;
    margin-top: 1rem;
  }
  .sendBtn {
    margin-top: 88px;
  }

  .bigBtnContent {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      margin-right: 10px;
    }
    span {
      display: inline-block;
      text-align: left;
    }
  }

  .excelBtn {
    position: relative;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    img {
      width: 15px;
      margin-bottom: 5px;
      margin-right: 3px;
      display: inline-block;
      filter: brightness(0.4);
    }

    &::after {
      /* underline */
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: black;
    }
  }

  /* padding-top: 230px; temp */
  .voucherFilter {
    top: 0;
    z-index: 2;
    margin-top: 6rem;
    border-top-left-radius: 0;
    white-space: pre-line;
    /* position: absolute; */
    /* overflow: hidden; */
    /* height: 183px; temp */
    /* transition: 0.5s; */
    /* background: #fff; */
    width: 78%;
    display: inline-block;
    vertical-align: top;
    padding-right: 6%; // prevent to collide btn

    &:hover {
      /* height: 520px; */
    }

    .tabs {
      position: absolute;
      top: -5.8rem;
      left: 0;

      .tab {
        border-bottom: 0;
      }

      .tab:first-child {
        border-bottom-left-radius: 0;
      }

      .tab:last-child {
        border-bottom-right-radius: 0;
      }
    }

    .voucherFilterBtn {
      position: absolute;
      right: 3rem;
      top: 4.5rem;
      /* margin-bottom: 1.5rem; */
      /* margin-left: 2rem; */
    }
  }
  .info {
    /* margin-top: -3rem; */
    max-height: 183px;
  }

  h2 {
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 6rem;
  }

  .introBox {
    min-height: 40rem;
  }

  .intros {
    margin-top: 4rem;
    .intro {
      position: relative;
      min-width: 23.5rem;
      margin-bottom: 3rem;
      .link {
        display: inline-block;
        color: ${palette.salmon};
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
      .date {
        font-size: 1.2rem;
      }
      .downloadBtn {
        position: absolute;
        display: flex;
        align-items: center;
        right: 0;
        /* bottom: 0; */
        border: 0.1rem solid #d7dbec;
        color: #5a607f;
        padding: 0.1rem 1.2rem;
        height: 2.4rem;
        font-size: 1.4rem;
        border-radius: 2rem;
        margin-top: -1rem;
        cursor: pointer;

        img {
          width: 2.4rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
`;

export default VoucherSearch;

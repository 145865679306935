import { useState } from "react";
import { useSWRConfig } from "swr";
import useSWRtoGet from "../../useSWRtoGet";

export default ({
  startDate,
  endDate,
  quantity,
  atrBuilding,
  status,
  city,
  roomCode,
  voucherCode,
  atrManagement,
  target,
  group
}) => {
  const [purposelyDelay, setPurposelyDelay] = useState(false);
  const { mutate } = useSWRConfig(); // for API resend
  console.log(group);
  const url = `${process.env.REACT_APP_API_HOST}/api/voucher/get_vouches?${
    startDate ? `start_date=${startDate}&` : ""
  }${endDate ? `end_date=${endDate}&` : ""}page_index=1&page_size=${
    quantity || "10"
  }&atr_building=${atrBuilding || "一般宅"}&status=${status || "未送鼎新"}${
    city && city !== "全部" ? `&city=${city}` : ""
  }${roomCode ? `&room_code=${roomCode}` : ""}${
    voucherCode ? `&voucher_code=${voucherCode}` : ""
  }${
    atrManagement && atrManagement !== "全部"
      ? `&atr_management=${atrManagement}`
      : ""
  }${target ? `&target=${target}` : ""}${group ? `&group=${group}` : ""}`;
  const { data, error, isLoading } = useSWRtoGet(url);
  return {
    vouchers: !purposelyDelay ? data?.result?.vouchers : null,
    totalCount: data?.result?.total_count,
    error,
    isLoading,
    resendAPI: () => {
      setPurposelyDelay(true);
      mutate(url);
      setTimeout(() => {
        setPurposelyDelay(false);
      }, 50);
    }
  };
};

// [
//   {
//     voucher_id: null,
//     voucher_code: null,
//     room_code: "",
//     target: "",
//     fee_owner: "",
//     amount: null,
//     group_name: "",
//     created: "",
//     status_forwarded: null,
//     fees: [],
//     cancelCustomColumn: true
//   }
// ]

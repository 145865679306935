/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import { palette } from '../../style/palette';

function InputTitle({
  required,
  remark,
  question,
  className,
  children,
  hideStarSign,
  name,
  // inlineBlock, width
}) {
  return (
    children ? (
      <h3 className={className}>
        {children}
        {required && !hideStarSign ? <span className="requireIcon">*</span> : null}
        {name ? <ErrorMessage component="span" className="errorMessage" name={name} /> : null}
        {question ? (
          <div className="questionMark">
            <span className="questionIcon">？</span>
            <span className="questionAnswer">{question}</span>
          </div>
        ) : null}
        {remark ? (
          <span className="remark">
            (
            {remark}
            )
          </span>
        ) : null}
      </h3>
    ) : null
  );
}

const StyledInputTitle = styled(InputTitle)`
  /* position: relative; */
  word-break: keep-all;
  ${(props) => (props.inlineBlock
    ? `
    display: inline-block;
    margin-right: 1rem;
  `
    : '')}
  font-weight: 400;
  font-size: 1.4rem;
  /* margin: 0 2.5rem 0 0; */
  ${(props) => (props.width
    ? `
      width: ${props.width / 10}rem;
  `
    : '')}

  .errorMessage {
    position: absolute;
    color: ${palette.warningRed};
    font-weight: 500;
    font-size: 1.15rem;
    margin-left: 0.8rem;
    word-break: keep-all;
    margin-top: 3rem;
    right: 0rem;
  }

  .requireIcon {
    color: ${palette.warningRed};
    margin-left: 0.3rem;
    /* position: absolute; */
    /* left: -1rem; */
    /* margin-left: -2rem; */
  }

  .remark {
    color: ${palette.darkGray};
    margin-left: 0.5rem;
  }

  .questionMark {
    display: inline-block;
    .questionIcon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: ${palette.gray};
      color: ${palette.darkestGray};
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
      font-size: 1.2rem;
      margin-left: 1rem;
      cursor: pointer;
      vertical-align: top;
    }

    .questionAnswer {
      display: none;
      font-size: 1.3rem;
      margin-left: 1rem;
      width: auto;
      height: 2rem;
      padding: 0 1rem;
      border-radius: 1rem;
      background-color: ${palette.lightGray};
      color: ${palette.darkestGray};
      font-weight: 400;
    }

    &:hover {
      .questionAnswer {
        display: inline-block;
      }
    }
  }
`;

export default StyledInputTitle;

import { createGlobalStyle } from 'styled-components';
import { palette } from './palette';

export default createGlobalStyle`

  html {
    scroll-behavior: smooth;
    font-size: 62.5%;
    font-family: 'Roboto', 'Microsoft JhengHei', '微軟正黑體', 'Noto Sans TC', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif;
  }

  @media screen and (max-width: 1440px) {
    html {
      font-size: 58%;
    }
  }

  body {
      font-size: 1.4rem;
      line-height: 20px;
      background: #F2F2F2;
  }

  @media screen and (max-width: 1280px){
    body {
      width: 1280px;
    }
  }

  /* sweet alert */
  .largeAlert {
    min-width: 40rem;

    a {
      color: ${palette.salmon};
      text-decoration: underline;
    }
  }

  .text-left {
    text-align: left !important;
  }

  .swal2-styled.swal2-confirm, .swal2-popup.swal2-toast .swal2-styled, .swal2-styled.swal2-cancel {
    /* Confrim Button */
    background: linear-gradient(#ffa44b, #fb8634) !important;
    border-radius: 50px !important;
    font-size: 1.4rem !important;
    padding: 0.9rem 2.4rem !important;
  }

  .swal2-styled.swal2-cancel {
    /* Cancel Button */
    background: #fff !important;
    color: #fb8634 !important;
    border: #fb8634 1px solid !important;
  }

  .swal2-actions {
    /* button margin top */
    margin-top: 2rem !important;
  }

  .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
    /* button hover */
    background: linear-gradient(#ffa44b, #fb8634) !important;
  }

  .swal2-actions:not(.swal2-loading) .swal2-styled.swal2-cancel:hover {
    background: white !important;
  }

  .swal2-html-container, .swal2-popup.swal2-toast .swal2-title {
    font-size: 1.4rem !important;
    line-height: 2.2rem !important;
  }

  .swal2-popup.swal2-toast .swal2-icon {
    margin: 0 1.5rem 0 1.5rem !important;
    transform: scale(1.5) !important;
  }

  body.swal2-toast-shown .swal2-container.swal2-top {
    top: 1.3rem !important;
  }


  /* popup package */
  .popup-overlay {
      background: rgba(0, 0, 0, 0.2);
  }
  .popup-content {
      display: flex;
      align-items: center;
      justify-content: center;
  }
`;

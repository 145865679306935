/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Routes } from "react-router-dom";
import publicRoutes from "./routes/publicRoutes";
import privateRoutes from "./routes/privateRoutes";
import NotFound from "./pages/NotFound";
import AuthRoute from "./routes/AuthRoute";
import PublicRoute from "./routes/PublicRoute";

function App() {
  return (
    <Routes>
      {publicRoutes.map(({ path, component, backUrl, ...routes }) => (
        <Route
          key={path}
          path={path}
          element={<PublicRoute component={component} backUrl={backUrl} />}
          {...routes}
        />
      ))}
      {privateRoutes.map(
        ({
          path,
          component,
          backUrl,
          ...routes // role
        }) => (
          <Route
            key={path}
            path={path}
            element={<AuthRoute component={component} backUrl={backUrl} />}
            {...routes}
          />
        )
      )}
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}

export default App;

/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from 'react';
import { Field } from 'formik';
import styled from 'styled-components';
import checkboxStyle from '../../style/form/checkbox';

function RawCheckbox({
  text, className, name, value,
}) {
  return (
    <label className={className}>
      {text}
      <Field name={name} value={value} type="checkbox" />
      <span className="checkmark" />
    </label>
  );
}

const Checkbox = styled(memo(RawCheckbox))`
  ${checkboxStyle}
`;

export default memo(Checkbox);

import axios from "axios";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";

// note: return a function
export default () => {
  const [cookies] = useCookies(["token"]);
  return async (feeId) => {
    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_HOST}/api/fee/${feeId}`,
        method: "put",
        headers: {
          //   'Client-Type': 'Web',
          Authorization: `Bearer ${cookies.token}`
        },
        data: {
          provider: "bili",
          fields: ["amount"]
        }
      });
      if (res.status === 200) {
        // console.log(res);
        Swal.fire({
          title: "更新成功",
          text: "已送出一筆費用的更新要求",
          icon: "success",
          confirmButtonText: "確定"
        }).then((result) => {
          if (result) window.location.reload();
        });
      }
      return res;
    } catch (error) {
      //   console.log(error.response);
      Swal.fire({
        title: error.response.statusText,
        text: error.response.data,
        icon: "error",
        confirmButtonText: "確定"
      });
      return error.response;
    }
  };
};

/* eslint-disable react/button-has-type */
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { palette } from "../../style/palette";

const RawButton = React.forwardRef(
  (
    {
      className,
      children,
      onClick,
      disabled,
      to,
      type,
      targetBlank
      // color, background, hollow, large, right, isBlock
    },
    ref
  ) => {
    const selfRef = useRef();
    const handleClick = () => {
      if (onClick) onClick();
      // e.nativeEvent.stopImmediatePropagation();
      // selfRef.current?.classList.add('active');
    };
    // useEffect(() => {
    //   document.getElementById('root').addEventListener('click', () => {
    //     selfRef.current?.classList.remove('active'); // off click
    //   });
    // }, []);

    return !to ? (
      <button
        className={className}
        onClick={(e) => handleClick(e)}
        disabled={disabled}
        ref={ref || selfRef}
        type={type || "button"}
      >
        {children}
      </button>
    ) : (
      <Link
        to={to}
        className={className}
        onClick={(e) => handleClick(e)}
        disabled={disabled}
        ref={selfRef}
        target={targetBlank && "_blank"}
        rel="noopener noreferrer"
      >
        {children}
      </Link>
    );
  }
);

const Button = styled(RawButton)`
  border: 1px solid ${palette.orange};
  background: ${palette.mainColor};
  display: inline-block;
  /* letter-spacing: 0.1em; */
  line-height: 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  word-break: keep-all;
  min-width: 28.8rem;
  text-align: center;

  .icon {
    /* height: 14px; */
    width: 15px;
    margin-right: 7px;
    vertical-align: -15%;
  }

  /* position */
  position: ${(props) => (props.right ? "absolute" : "relative")};
  ${(props) => (props.right ? "right: 10px;" : "")}

  /* color */
  color: ${palette.lightGray};
  ${(props) =>
    props.color
      ? `
    color: ${props.color};
  `
      : ""};

  ${(props) =>
    props.background
      ? `
    background: ${props.background};
    border-color: ${props.background};
    /* note: if there's no pointed borderColor, use backgroundColor instead */
  `
      : ""}
  ${(props) =>
    props.borderColor
      ? `
    border-color: ${props.borderColor};
  `
      : ""}

  /* size */
  font-size: 16px;
  padding: 13px 70px;
  margin: 10px 10px 20px 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);

  ${(props) =>
    props.little &&
    `
    min-width: auto;
    font-size: 14px;
    padding: 8px 20px;
    box-shadow: none;
    `}

  ${(props) =>
    props.mini &&
    `
    min-width: auto;
    font-size: 14px;
    padding: 4px 10px;
    box-shadow: none;
    `}

  ${(props) =>
    props.huge &&
    `
    padding: 18px 34px;
    min-width: 18.8rem;
    `}

  ${(props) =>
    props.isBlock &&
    `
      display: block;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
  `}

  /* hollow */
  ${(props) => (props.hollow ? "background: transparent;" : "")}
  ${(props) => (props.hollow ? `color: ${props.background};` : "")}
  ${(props) => (props.hollow ? "font-weight: bolder;" : "")}

  &:hover {
    opacity: 0.95;
    filter: brightness(105%) contrast(108%) grayscale(20%);
    /* border-color: transparent; */
  }

  &:active {
    /* border-color: ${palette.salmon}; */
    transform: translate(1px, 1px);
  }

  &:disabled {
    opacity: 1;
    filter: grayscale(90%) brightness(110%);
    cursor: not-allowed;
  }

  &.active {
    /* border-color: ${palette.salmon}; */
  }
`;

RawButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  to: PropTypes.string,
  type: PropTypes.string
};

RawButton.defaultProps = {
  className: "",
  children: null,
  onClick: null,
  disabled: false,
  to: null,
  type: ""
};

export default Button;

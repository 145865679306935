/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo } from 'react';
import styled from 'styled-components';
import InputTitle from '../title/InputTitle';

function RawInputContainer({
  name,
  title,
  required,
  question, // add question mark to introduce this input beside title
  lineUp = false, // add <br> after input
  hideStarSign, // when input is required, it has '*' sign
  withoutParent, // make <span> as <>
  className,
  children,
  // isBlock
}) {
  const TitleComponent = (
    <InputTitle
      required={required}
      question={question}
      hideStarSign={hideStarSign}
      name={name} // for errorMessage
      inlineBlock
    >
      {title}
    </InputTitle>
  );
  return (
    <>
      {!withoutParent ? (
        <span className={className}>
          {TitleComponent}
          {children}
        </span>
      ) : (
        <>
          {TitleComponent}
          {children}
        </>
      )}
      {lineUp ? <br /> : null}
    </>
  );
}

const InputContainer = styled(memo(RawInputContainer))`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 2.4rem;
  ${(props) => props.spacebetween && `
    justify-content: flex-end;
    width: 100%;
  `}
  ${(props) => props.isblock
    && `
      width: 100%;
  `}
`;
export default memo(InputContainer);

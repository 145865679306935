import React, { memo } from "react";
// import PropTypes from "prop-types";
import styled from "styled-components";
import SectionTitle from "./title/SectionTitle";

function RawWhitePaper({ title, className, children }) {
  return (
    <div className={className}>
      {title ? <SectionTitle>{title}</SectionTitle> : null}
      {children}
    </div>
  );
}

// RawWhitePaper.propTypes = {
//   title: PropTypes.string,
//   className: PropTypes.string,
//   children: PropTypes.oneOfType([
//     PropTypes.arrayOf(PropTypes.node),
//     PropTypes.node
//   ])
// };

RawWhitePaper.defaultProps = {
  title: "",
  className: "",
  children: null
};

const WhitePaper = styled(memo(RawWhitePaper))`
  position: relative;
  /* overflow: hidden; */
  margin: 2rem 3rem 2rem 0;
  padding: ${(props) => (props.title ? "5px" : "20px")} 26px 10px 26px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  filter: drop-shadow(20px 20px 20px rgba(0, 0, 0, 0.04));
`;

export default memo(WhitePaper);

/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { palette } from 'style/palette';
import styled from 'styled-components';
import Input from '.';

function RawInputPassword({
  className,
  type,
  passPasswordVisible,
  forcePasswordVisible,
  ...props
}) {
  // note: passPasswordVisible & forcePasswordVisible are not nessacery
  // these two props are outside control for password visibility
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const changeVisibleState = () => {
    // use outside set function
    if (forcePasswordVisible) forcePasswordVisible(!isPasswordVisible);
    else setPasswordVisible(!isPasswordVisible);
  };
  useEffect(() => {
    // when outside state change, inter would change, too
    setPasswordVisible(passPasswordVisible);
  }, [passPasswordVisible]);
  return (
    <Input
      type={!isPasswordVisible ? 'password' : 'text'}
      {...props}
      // style={{ paddingRight: '4rem' }}
      decoration={(
        <span
          className={`${className} eye_${
            isPasswordVisible ? 'close' : 'open'
          } cursor-pointer text-4xl absolute mt-8`}
          onClick={changeVisibleState}
          role="button"
          tabIndex={0}
          aria-label="See password"
        />
        )}
    />
  );
}

const InputPassword = styled(RawInputPassword)`
  /* note that these css only for eye span */
  color: ${palette.salmon};
  margin-left: -7rem;
  margin-top: 1.4rem;

  ${(props) => props.isBlock
    && `
    right: 1rem;
    margin-left: 0;
  `}
`;

export default InputPassword;

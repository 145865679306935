/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
/* eslint-disable react/no-array-index-key */
import React, { memo } from "react";
import styled from "styled-components";
// import { Link } from 'react-router-dom';
import { palette } from "../style/palette";
import Container from "./Container";
// import Image from './Image';

function RawLogInLayout({ className, children }) {
  return (
    <Container className={className}>
      <div className="rightForm">
        <div className="form">{children}</div>
      </div>
    </Container>
  );
}

const LogInLayout = styled(memo(RawLogInLayout))`
  .leftOrange {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 50%; */
    min-height: 100vh;
    background: ${palette.mainColor};

    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 20%;
      img {
        width: 100%;
      }
    }
  }
  .rightForm {
    position: absolute;
    top: 8vh;
    left: 30%;
    width: 40%;
    height: 84vh;
    /* min-height: 100vh; */
    /* background-color: #f2f2f2; */
    background-color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;

    .titleGrp {
      text-align: center;
      margin-bottom: 4rem;
      .title {
        font-size: 2.8rem;
        line-height: 4rem;
        margin-bottom: 2rem;
        font-weight: 500;
      }
      .subTitle {
        color: ${palette.darkerGray};
        font-size: 1.6rem;
      }
    }

    .form {
      position: relative;
      width: 55%;

      .title {
        /* margin-bottom: 45px; */
      }

      .links {
        display: inline-block;
        position: absolute;
        right: 0;
        margin-top: 16px;

        a,
        button {
          margin: 0 3px;
          color: ${palette.orange};
          text-decoration: underline;
        }
      }

      .warningMessage {
        position: absolute;
        font-size: 13px;
        color: ${palette.warningRed};
        background: ${palette.pink};
        padding: 7px 17px;
        width: calc(100% - 34px);
        margin-top: -13px;
      }
    }
  }
`;

export default memo(LogInLayout);

/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import Table from "components/table";
import WhitePaper from "components/WhitePaper";
import React, { useState } from "react";
import styled from "styled-components";
import Layout from "components/FormLayout";
import Button from "components/button";
import Form from "components/Form";
import Input from "components/input";
import InputDate from "components/input/InputDateRange";
import useVoucher from "hooks/api/voucher/useVoucher";
import { useParams } from "react-router-dom";
import useUnboundFees from "hooks/api/fee/useUnboundFees";
import useUnbindingFee from "hooks/api/voucher/useUnbindingFee";
import { palette } from "style/palette";
import useBindingFee from "hooks/api/fee/useBindingFee";
import useSyncFee from "hooks/api/fee/useSyncFee";

function RawVoucherEach({ className }) {
  const { id } = useParams();
  const syncFee = useSyncFee();
  const { voucher } = useVoucher(id);
  const unbindingFee = useUnbindingFee();
  const bindingFee = useBindingFee();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  const [startDate, setStartDate] = useState(
    sevenDaysAgo.toISOString().split("T")[0].split("-").join("/")
  ); // yyyy/mm/dd
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0].split("-").join("/")
  ); // yyyy/mm/dd
  const [subject, setSubject] = useState("");
  const { unboundFees } = useUnboundFees({
    quantity: 10,
    voucherId: id,
    subject,
    startDate,
    endDate
  });
  return (
    <Layout breadcrumbs={false} title="匯入單查詢 > 匯入單編輯">
      {voucher && voucher.status_forwarded === 1 ? (
        <div className={className}>
          <Form
            initialValues={{
              voucherCode: voucher?.voucher_code,
              roomCode: voucher?.room_code,
              amount: voucher?.amount,
              groupName: voucher?.group_name,
              created: voucher?.created,
              status:
                voucher?.status_forwarded === 1
                  ? "未送鼎新"
                  : voucher?.status_forwarded === 2
                  ? "處理中"
                  : voucher?.status_forwarded === 3
                  ? "鼎新已接收"
                  : voucher?.status_forwarded === 4
                  ? "立帳成功"
                  : voucher?.status_forwarded === 5
                  ? "被退回"
                  : voucher?.status_forwarded === 6
                  ? "作廢"
                  : null
            }}
            enableReinitialize
            validate={() => {
              const errors = {};
              return errors;
            }}
            onSubmit={() => {
              // setAPIStartDate(values.startDate);
              // setAPIEndDate(values.endDate);
              // setAPIType(values.type);
              // setNowPage(1);
              // resendAPI();
            }}
          >
            <div className="upperArea">
              <WhitePaper className="info">
                <Input
                  name="voucherCode"
                  title="匯入單號"
                  placeholder=""
                  disabled
                  spacebetween
                />
                <Input
                  name="roomCode"
                  title="房代碼"
                  placeholder="此格僅為範例"
                  disabled
                  spacebetween
                />
                <Input
                  name="amount"
                  title="總額"
                  placeholder="此格僅為範例"
                  disabled
                  spacebetween
                />
                <Input
                  name="groupName"
                  title="類別"
                  placeholder="此格僅為範例"
                  disabled
                  spacebetween
                />
                <Input
                  name="created"
                  title="圈帳日期"
                  placeholder="此格僅為範例"
                  disabled
                  spacebetween
                />
                <Input
                  name="status"
                  title="狀態"
                  placeholder="此格僅為範例"
                  disabled
                  spacebetween
                />
                {/* <Button className="infoBtn" type="submit" little>
                查詢
              </Button> */}
              </WhitePaper>
              <WhitePaper className="feeTable">
                <h2 style={{ marginLeft: "1rem" }}>單別所含費用</h2>
                {voucher && (
                  <Table
                    customColumnTitleBefore="動作"
                    customColumnBefore={(fee) => (
                      <>
                        <Button
                          className="collapsedBtn"
                          background="#f86e70"
                          mini
                          onClick={() =>
                            unbindingFee(voucher.voucher_id, fee.fee_id)
                          }
                        >
                          剔除
                        </Button>
                        {voucher?.status_forwarded === 1 && (
                          <Button
                            className="collapsedBtn"
                            background="#7bbf43"
                            mini
                            onClick={() => syncFee(fee.fee_id)}
                          >
                            更新
                          </Button>
                        )}
                      </>
                    )}
                    objects={voucher.fees}
                    order={[
                      {
                        key: "room_code",
                        title: "房代碼"
                      },
                      {
                        key: "contract_id",
                        title: "合約編號"
                      },
                      {
                        key: "subject",
                        title: "科目"
                      },
                      {
                        key: "amount",
                        title: "金額"
                      },
                      {
                        key: "atr_tax",
                        title: "稅別"
                      },
                      {
                        key: "atr_certificate",
                        title: "憑證別"
                      },
                      {
                        key: "atr_fee_type",
                        title: "收付條件"
                      },
                      {
                        key: "due_time",
                        title: "應收/代收日期",
                        format: (item) => item.due_time.split("T")[0]
                      },
                      {
                        key: "atr_landlord_is_legal",
                        title: "房東人格別"
                      },
                      {
                        key: "atr_tenant_is_legal",
                        title: "房客人格別"
                      },
                      {
                        key: "atr_ar_info",
                        title: "應收對象"
                      },
                      {
                        key: "atr_ap_info",
                        title: "應付對象"
                      },
                      {
                        key: "exe_time",
                        title: "預計收付日",
                        format: (item) => item.exe_time.split("T")[0]
                      }
                    ]}
                  />
                )}
              </WhitePaper>
            </div>
          </Form>
          <div className="fees">
            <img
              className="swipeUp"
              src="/images/icons/swipe-up.png"
              alt="upper arrow"
            />
            <Form
              initialValues={{
                subject,
                date: [startDate, endDate]
              }}
              validate={() => {}}
              onSubmit={(values) => {
                setStartDate(values.date[0]);
                setEndDate(values.date[1]);
                setSubject(values.subject);
              }}
            >
              <Input
                name="subject"
                title="科目"
                // placeholder="尚無 API"
                // lineUp
              />
              <InputDate
                name="date"
                title="應繳/代收日期"
                // placeholder="尚無 API"
                required
                hideStarSign
                // size="30"
              />
              <Button className="feesBtn" little type="submit">
                搜尋
              </Button>
            </Form>
            <Table
              customColumnTitleBefore="動作"
              customColumnBefore={(fee) => (
                <Button
                  className="collapsedBtn"
                  background="#7bbf43"
                  mini
                  onClick={() => bindingFee(voucher.voucher_id, fee.fee_id)}
                >
                  加入
                </Button>
              )}
              objects={unboundFees}
              order={[
                {
                  key: "subject",
                  title: "科目"
                },
                {
                  key: "due_time",
                  title: "應收/代收日期"
                },
                {
                  key: "amount",
                  title: "金額"
                }
              ]}
            />
          </div>
        </div>
      ) : ""}
    </Layout>
  );
}

const VoucherEach = styled(RawVoucherEach)`
  .fees {
    background-color: white;
    filter: drop-shadow(20px 20px 20px rgba(0, 0, 0, 0.04));
    position: fixed;
    /* width: 91.2%; */
    width: calc(75.27% - 37.635px);
    right: 3%;
    padding: 5rem;
    border-radius: 10px;
    top: 93vh;
    transition-duration: 0.7s;
    /* border: ${palette.darkGray} 1px solid; */
    height: 70vh;
    overflow: scroll;

    &:hover {
      top: 30vh;

      .swipeUp {
        opacity: 0;
      }
    }

    .feesBtn {
      margin-bottom: 1.5rem;
      margin-left: 8rem;
    }

    .swipeUp {
      position: absolute;
      width: 3rem;
      top: 1rem;
      left: 50%;
      margin-left: -1.5rem;
      opacity: 0.2;
      transition-duration: 0.7s;
    }
  }
  .upperArea {
    position: absolute;
    width: 96.5%;
    /* height: 105px; */
    display: flex;
  }
  .info {
    /* position: absolute; */
    position: relative;
    /* overflow: hidden; */
    /* height: 183px; */
    transition: 0.5s;
    background: transparent;
    min-width: 18%;
    width: 18%;
    padding-right: 0;

    .infoBtn {
      margin-bottom: 1.5rem;
      margin-left: 2rem;
    }
  }
  .feeTable {
    /* margin-top: -3rem; */
    /* max-height: 183px; */
    width: 78%;
    max-height: 76vh;
    overflow: scroll;
  }

  h2 {
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 6rem;
  }

  .introBox {
    min-height: 40rem;
  }

  .intros {
    margin-top: 4rem;
    .intro {
      position: relative;
      min-width: 23.5rem;
      margin-bottom: 3rem;
      .link {
        display: inline-block;
        color: ${palette.salmon};
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
      .date {
        font-size: 1.2rem;
      }
      .downloadBtn {
        position: absolute;
        display: flex;
        align-items: center;
        right: 0;
        /* bottom: 0; */
        border: 0.1rem solid #d7dbec;
        color: #5a607f;
        padding: 0.1rem 1.2rem;
        height: 2.4rem;
        font-size: 1.4rem;
        border-radius: 2rem;
        margin-top: -1rem;
        cursor: pointer;

        img {
          width: 2.4rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
`;

export default VoucherEach;

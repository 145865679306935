/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { palette } from '../../style/palette';
import Links from './Links';
// import { fadeOut } from '../../style/keyframes';

function RawCategory({
  className,
  category,
  // onClick,
  title,
  sidebarCollapsed,
  // active,
}) {
  const [categoryCollapse, setCategoryCollapse] = useState(false);
  const handleClick = () => {
    if (!sidebarCollapsed) setCategoryCollapse(!categoryCollapse);
  };
  return (
    <>
      <div className={className} onClick={handleClick} role="button" tabIndex={0}>
      <img
          className='icon'
          src={`/images/icons/${category.iconCode}.png`}
          alt={category.iconCode}
        />
        <span className="titleText">{title}</span>
        <img
          className={`arrow${categoryCollapse ? ' activeArrow' : ''}`}
          src="/images/icons/arrow.png"
          alt="arrow"
        />
        {/* <Links className="dropdown" category={category} /> */}
      </div>
      <Links category={category} collapsed={sidebarCollapsed || categoryCollapse} />
    </>
  );
}

RawCategory.propTypes = {
  // onClick: PropTypes.func.isRequired,
  category: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
  }).isRequired,
  className: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sidebarCollapsed: PropTypes.bool,
};

RawCategory.defaultProps = {
  sidebarCollapsed: false,
};

const Category = styled(memo(RawCategory))`
  position: relative;
  margin: 0;
  /* padding: 16px 21px; */
  padding: 14px 0px;
  font-size: 13px;
  /* font-weight: 500; */
  letter-spacing: 0.5px;
  /* background-color: #eee; */
  /* background-image: linear-gradient(to bottom, #fff 85%, #dedede); */
  /* border-bottom: 1px solid ${palette.gray}; */
  color: ${palette.black};
  word-break: keep-all;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  cursor: pointer;
  display: flex;
  align-items: center;

  ${(props) => props.sidebarCollapsed
    && `
    overflow: visible;
  `}

  ${(props) => props.active
    && `
    color: ${palette.salmon};
  `}

  .titleText {
    /* transition: 0.2s 0.2s; */
    ${(props) => props.sidebarCollapsed
      && `
      transition: 0.15s 0s;
      color: transparent;
    `}
  }

  .icon {
    /* position: absolute;
    left: 16px;
    top: 29%;
    height: 18px;
    margin-right: 12px; */
    /* vertical-align: -17%; */
    width: 17px;
    margin: 0 9px 0 17px;
    filter: invert(14%) sepia(90%) saturate(3759%) hue-rotate(223deg) brightness(99%) contrast(111%);
    opacity: 0.8;
  }

  .arrow {
    position: absolute;
    height: 50%;
    right: 10px;
    transform: rotate(180deg);
    transition: 0.3s 0.2s;

    ${(props) => props.sidebarCollapsed
      && `
      transition: 0.3s;
      opacity: 0;
    `}
  }

  .activeArrow {
    transform: rotate(0deg);
  }

  &:hover {
    .dropdown {
      /* display: block; */
    }
  }

  .dropdown {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    border: solid 1px rgb(209,224,238);
    border-bottom: 0;
    /* box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%); */
    height: auto !important;

    .link {
      li {
        border-bottom: rgb(209,224,238) 1px solid;
        background: white;
        padding-left: 1.5rem;
        padding-right: 1rem;
      }
    }

    .activeLink {
      li {
        border-right: 0;
        border-left: 4px solid ${palette.salmon};
      }
    }
  }
`;

export default memo(Category);

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate } from 'react-router-dom';
// import { useCookies } from 'react-cookie';
// import FirstResetPassword from 'pages/FirstResetPassword';
import useAuthToken from '../hooks/useAuthToken';

function AuthRoute({
  component,
  backUrl,
  // role
}) {
  // for users who haven't change their password
  // const [cookies] = useCookies();
  // if (cookies.is_first_login === 'true' && useAuthToken()) {
  //   return <FirstResetPassword />;
  // }
  // no token means user hasn't logged in
  if (!useAuthToken()) {
    return <Navigate to={backUrl} />;
  }
  return component;
}

export default AuthRoute;

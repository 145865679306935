import useSWRtoGet from "../../useSWRtoGet";

export default ({ quantity, voucherId, subject, startDate, endDate }) => {
  const { data, error } = useSWRtoGet(
    `${
      process.env.REACT_APP_API_HOST
    }/api/fee/get_unbind_fees?page_index=1&page_size=${quantity || 20}${
      voucherId ? `&voucher_id=${voucherId}` : ""
    }${subject ? `&subject=${subject}` : ""}${
      startDate ? `&start_date=${startDate}` : ""
    }${endDate ? `&end_date=${endDate}` : ""}`
  );
  return {
    unboundFees: data?.result?.fees,
    totalCount: data?.result?.total_count,
    error
  };
};

import React, { useState } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
// import { Link } from 'react-router-dom';
import styled from "styled-components";
import { useCookies } from "react-cookie";
import InputPassword from "components/input/InputPassword";
// import Swal from 'sweetalert2';
import useLogin from "../hooks/api/useLogin";
import useMockLogin from "../hooks/api/dev/useMockLogin";
import Button from "../components/button";
import Input from "../components/input";
import Checkbox from "../components/input/Checkbox";
import LogInLayout from "../components/LoginLayout";

function RawLogIn({ className }) {
  const login = useLogin(); // get login function
  const mockLogin = useMockLogin(); // get login function
  const [APIError, setAPIError] = useState(false); // if login error
  const [cookies] = useCookies(["username", "rememberMe"]); // get username by cookie if user check "remember me"

  // about loading
  const [isLoading, setIsLoading] = useState(false);
  const loadingStyle = {
    opacity: 0.5,
    pointerEvents: "none",
  };

  // form
  const initialValues = {
    account: cookies.rememberMe ? cookies.account : "",
    password: "",
    rememberMe: !!cookies.rememberMe,
  };
  const validationSchema = Yup.object().shape({
    // account: Yup.string().matches(/\d{8,11}$/, 'temp'),
  });
  return (
    <LogInLayout>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setAPIError(false); // if error last time, cancel it now
          setIsLoading(true);
          const result = await login({
            account: values.account,
            password: values.password,
            rememberMe: values.rememberMe,
          });
          if (result.status === 400) {
            setAPIError(true);
          } else if (result.status === 200) {
            setAPIError(false);
          }
          setIsLoading(false);
        }}
      >
        <Form className={className} style={isLoading ? loadingStyle : null}>
          <div className="titleGrp">
            <div className="title">YFS</div>
            <div className="subTitle">歡迎登入</div>
          </div>
          <Input
            name="account"
            title="帳號"
            isblock
            showError={APIError}
            required
            hideStarSign
          />
          <InputPassword
            name="password"
            title="密碼"
            isblock
            showError={APIError}
            required
            hideStarSign
          />
          <div className="addOn">
            <br />
            <Checkbox name="rememberMe" text="記住帳號" />
            <div className="links">
              {/* <button
                type="button"
                onClick={() => {
                  Swal.fire({
                    title: '忘記店家帳號',
                    html: '若您忘記店家帳號',
                    icon: 'info',
                    confirmButtonText: '確定',
                    customClass: {
                      popup: 'largeAlert',
                      htmlContainer: 'text-left',
                    },
                  });
                }}
              >
                忘記店家帳號
              </button>
              / */}
              {/* <Link to="/forgot-password">忘記密碼</Link> */}
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button className="btn" little type="submit">
              登入
            </Button>
            {/* <Button className="btn" little onClick={() => mockLogin()}>
              開發者
            </Button> */}
          </div>
        </Form>
      </Formik>
    </LogInLayout>
  );
}

RawLogIn.propTypes = {
  className: PropTypes.string,
};

RawLogIn.defaultProps = {
  className: "",
};

const LogIn = styled(RawLogIn)`
  .addOn {
    position: absolute;
    width: 100%;
    margin-top: -1.5rem;
  }
  .btn {
    margin-top: 7rem;
  }
`;

export default LogIn;

import React, { memo, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import { palette } from "../../style/palette";
import { fadeOut } from "../../style/keyframes";

function RawLinks({ category, collapsed, className }) {
  const nowLink = useLocation().pathname.split("/")[2];
  const [listHeight, setListHeight] = useState("auto");

  const linkRef = useRef(null);
  useEffect(() => {
    // note that I cannot use ref to record height
    // because user may collapse sidebar default
    // eslint-disable-next-line react/prop-types
    if (category && linkRef?.current) {
      const linkHeight = linkRef.current.offsetHeight;
      setListHeight(`${linkHeight * category.links.length + 2}px`);
    }
  }, [linkRef, category]);
  return (
    <ul
      className={className}
      style={{ height: collapsed ? "0px" : listHeight }}
    >
      {category.links.map((link) => (
        <Link
          className={`link${link.path === nowLink ? " activeLink" : ""}`}
          to={`/${category.path}/${link.path}`}
          key={link.text}
        >
          <li className="font-normal" ref={linkRef}>
            <div className="decoration" />
            {/* <span className={`icon ${link.path} text-6xl`} /> */}
            <span>{link.text}</span>
          </li>
        </Link>
      ))}
    </ul>
  );
}

RawLinks.propTypes = {
  category: PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
        text: PropTypes.string
      })
    )
  }).isRequired,
  className: PropTypes.string.isRequired,
  collapsed: PropTypes.bool
};

RawLinks.defaultProps = {
  collapsed: false
};

const Links = styled(memo(RawLinks))`
  margin: 0;
  padding-inline-start: 0px;
  overflow: hidden;
  transition-property: height;
  transition: 0.2s;

  .link {
    color: ${palette.black};
    text-decoration: none;
    cursor: pointer;
    ${(props) =>
      props.collapsed &&
      `
        transition: 0.5s;
        // color: transparent;
    `}

    li {
      position: relative;
      list-style: none;
      /* background: white; */
      background: rgb(250, 250, 250);
      /* border-bottom: 1px solid ${palette.gray}; */
      display: flex;
      align-items: center;
      word-break: keep-all;

      .decoration {
        display: none;
        position: absolute;
        background-color: rgb(0, 63, 217);
        width: 0.8rem;
        height: 60%;
        border-radius: 20px;
        left: -0.4rem;
      }

      span {
        /* width: 100%; */
        margin: 0.3rem 1.9rem;
        height: 4rem;
        padding: 0 2.5rem;
        border-radius: 10px;
        display: flex;
        align-items: center;
        word-break: keep-all;
        color: rgb(149, 164, 178);
        font-size: 13px;
        letter-spacing: 0.2px;
        font-weight: 400;
      }
      .fadeOut {
        animation: ${fadeOut} 0.2s 0.3s both;
      }

      .icon {
        position: absolute; // to prevent layout shift
        left: 2.5px;
        max-width: 23px;
        max-height: 23px;
        margin-right: 10px;
        margin-top: -6px;
      }

      &:hover {
        /* background: ${palette.salmon};
        color: white;
        transition-duration: 0s;
        img {
          filter: brightness(100);
        } */
        .decoration {
          display: block;
        }
        span {
          background-color: white;
          color: black;
          font-weight: 500;
        }
      }
    }
  }

  a:last-child {
    li {
    }
  }

  .activeLink {
    /* color: ${palette.salmon}; */
    li {
      /* background-color: #fee8d8;
      border-right: 4px solid ${palette.salmon};
      img {
        filter: invert(60%) sepia(92%) saturate(1383%) hue-rotate(336deg)
          brightness(99%) contrast(98%);
      }

      &:hover {
        background-color: #fee8d8;
        color: ${palette.salmon};
        img {
          filter: invert(60%) sepia(92%) saturate(1383%) hue-rotate(336deg)
            brightness(99%) contrast(98%);
        }
      } */
      .decoration {
        display: block;
      }
      span {
        background-color: white;
        color: black;
        font-weight: 500;
      }
    }
  }
`;

export default memo(Links);

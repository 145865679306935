import axios from "axios";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";

// note: return a function
export default () => {
  const [cookies, ,removeCookie] = useCookies(["token"]);
  return async (voucherIds, amount) => {
    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_HOST}/api/voucher/send_vouchers`,
        method: "post",
        headers: {
          //   'Client-Type': 'Web',
          Authorization: `Bearer ${cookies.token}`
        },
        data: {
          voucher_ids: voucherIds
        }
      });
      if (res.status === 200) {
        Swal.fire({
          title: "送出成功",
          html: `已成功將 ${voucherIds.length} 筆匯入單送至鼎新<br />總額 ${amount} 元`,
          icon: "success",
          confirmButtonText: "確定"
        });
      }
      return res;
    } catch (error) {
      if (error.response.status === 401) {
        const result = Swal.fire({
          title: "請重新登入",
          text: "token 已過期，請重新登入",
          icon: "error",
          confirmButtonText: "確定"
        });
        if (result) removeCookie('token', { path: '/' });
        return error.response;
      }
      if (error.response.status === 403) {
        Swal.fire({
          title: "沒有權限",
          text: error.response.statusText + error.response.data,
          icon: "error",
          confirmButtonText: "確定"
        });
        return error.response;
      }
      Swal.fire({
        title: error.response.statusText,
        text: error.response.data,
        icon: "error",
        confirmButtonText: "確定"
      });
      return error.response;
    }
  };
};

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthToken from '../hooks/useAuthToken';

function PublicRoute({ component, backUrl }) {
  // if user has logged in, they cannot enter this page
  if (useAuthToken()) {
    return <Navigate to={backUrl} />;
  }
  return component;
}

export default PublicRoute;

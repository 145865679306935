import React from "react";
import styled from "styled-components";
import checkboxStyle from "../../style/form/checkbox";

function Checkbox({ text = "確認", className, onChange, checked, onClick }) {
  return (
    <label className={className}>
      {/* {text} */}
      <input checked={checked} type="checkbox" onChange={onChange} onClick={onClick} />
      <span className="checkmark" />
    </label>
  );
}

const StyledCheckbox = styled(Checkbox)`
  ${checkboxStyle}
  .checkmark {
    left: 18px;
    margin-top: 15px;
  }
`;

export default StyledCheckbox;

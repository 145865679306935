/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form as FormikForm } from 'formik';
// import PageTitle from './title/PageTitle';

function RawForm({
  children, className, style, ...props
}) {
  return (
    <div className={className}>
      <Formik
        // these props are all for Formik
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <FormikForm style={style}>{children}</FormikForm>
      </Formik>
    </div>
  );
}

RawForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
};

RawForm.defaultProps = {
  className: '',
};

const Form = styled(RawForm)``;

export default Form;

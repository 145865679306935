import { css } from "styled-components"
import { palette } from "../palette"

export default css`
    color: ${palette.black};
    display: inline-block;
    position: relative;
    padding-left: 26px;
    margin: 0px 15px 30px 0;
    /* margin-top: 10px; */
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        & ~ .checkmark {
            /* background-color: ${palette.gray}; */
            background-color: transparent;
            border: ${palette.darkGray} 1px solid;
            border-radius: 2px;
        }
        &:checked ~ .checkmark {
            background-color: ${palette.blue};
            border-color: ${palette.blue};

            &:after {
                display: block;
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #eee;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 4px;
            top: 0px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

`
// mainColor: 'linear-gradient(#ffa44b, #fb8634)',
export const palette = {
  mainColor: "linear-gradient(#88CAF6, #59A1F6)",
  lightBlue: "#E5F3FF",
  warningRed: "#f61515",
  pink: "#fff0f0",
  darkestGray: "rgba(0, 0, 0, 0.6)",
  darkerGray: "#92929d",
  darkGray: "#ccc",
  gray: "rgb(238,238,238)",
  lightGray: "#F7F7F7",
  darkBlue: "#5191E6",
  darkerBlue: "#182341",
  darkestBlue: "#0F182C",
  black: "#292929",
  green: "#74B666",
  blue: "#39a3fd",
  darkYellow: "#856404",
  yellow: "#FFF3CD",
  orange: "#88CAF6", // note that change to blue
  salmon: "#59A1F6", // note that change to blue
  darkSalmon: "#e45a13",
  blueFilter:
    "invert(68%) sepia(59%) saturate(523%) hue-rotate(179deg) brightness(105%) contrast(93%)"
};

export const media = {};

export default [
  {
    path: 'setting',
    title: '首頁',
    links: [
      {
        path: 'edit-password',
        text: '變更密碼',
      },
      {
        path: 'edit-account',
        text: '帳號設定',
      },
    ],
  },
  {
    path: 'voucher',
    title: '立帳功能',
    iconCode: 'record',
    links: [
      {
        path: 'search',
        text: '匯入單查詢',
      },
    ],
  },
  // {
  //   path: 'balance',
  //   title: '沖帳功能',
  //   iconCode: 'guide',
  //   links: [
  //     {
  //       path: 'search_all',
  //       text: '一般繳費記錄查詢',
  //     },
  //     {
  //       path: 'search',
  //       text: '可沖帳繳費記錄查詢',
  //     },
  //   ],
  // },
];

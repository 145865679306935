import { css } from 'styled-components';

export default css`
  min-width: 13rem;
  vertical-align: bottom;
  box-sizing: border-box;
  padding: 0.8rem 4rem 0.7rem 2.4rem;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  ${(props) => props.disabled
    && `
    background-image: none;
  `}
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 5px;
  vertical-align: -1.7rem;

  ${(props) => props.isLoading
    && `
      background-image: none;
  `}


  /* overflow: hidden;
  line-height: initial !important; */
`;

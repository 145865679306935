/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import select from 'style/form/select';
import styled from 'styled-components';
import Input from '.';

function RawSelect({
  options,
  placeholder,
  dataValue,
  title,
  name,
  className,
  withoutPlaceHolder,
  ...inputProps
  // fontSize, isBlock,
}) {
  return (
    <Input
      className={className}
      isSelect
      title={title}
      data-value={dataValue}
      placeholder={false} // handle by option
      name={name}
      // withoutParent
      {...inputProps}
    >
      {(title || placeholder) && !withoutPlaceHolder && (
        <option value="">{placeholder || (title ? `請輸入${title}` : '')}</option>
      )}
      {options?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      ))}
    </Input>
  );
}

const Select = styled(RawSelect)`
  ${select};
`;

export default Select;

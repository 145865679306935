import axios from "axios";
import { useCookies } from "react-cookie";
import Swal from "sweetalert2";

// note: return a function
export default () => {
  const [cookies] = useCookies(["token"]);
  return async (voucherId, feeId) => {
    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_HOST}/api/voucher/unbind_fee`,
        method: "post",
        headers: {
          //   'Client-Type': 'Web',
          Authorization: `Bearer ${cookies.token}`
        },
        data: {
          voucher_id: voucherId,
          fee_id: feeId
        }
      });
      if (res.status === 200) {
        Swal.fire({
          title: "剔除成功",
          text: "已成功將一筆費用剔除至單據外",
          icon: "success",
          confirmButtonText: "確定"
        }).then((result) => {
          if (result) window.location.reload();
        });
      }
      return res;
    } catch (error) {
      //   console.log(error.response);
      Swal.fire({
        title: error.response.statusText,
        text: error.response.data,
        icon: "error",
        confirmButtonText: "確定"
      });
      return error.response;
    }
  };
};

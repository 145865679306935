/* eslint-disable react/prop-types */
import React, { memo, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
// import { useCookies } from "react-cookie";
import { palette } from "../style/palette";
import Container from "./Container";
import Header from "./Header";
import Sidebar from "./sidebar";
// import PageTitle from "./title/PageTitle";

function RawLayout({ title, className, children, onScroll }) {
  // , breadcrumbs
  // const [cookies] = useCookies();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);
  // note set in header button

  // useLayoutEffect(() => {
  //   setSidebarCollapsed(cookies.collapsed);
  // }, []);
  return (
    <Container className={className}>
      <Sidebar
        sidebarCollapsed={isSidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div
        className="rightSidePage"
        style={isSidebarCollapsed ? {} : { width: "85%" }}
      >
        <Header
          isSidebarCollapsed={isSidebarCollapsed}
          // setSidebarCollapsed={setSidebarCollapsed}
          title={title}
        />
        <div className="scrollBox" onScroll={onScroll}>
          <div
            className="contentBox"
            // style={
            //   isSidebarCollapsed
            //     ? { paddingLeft: "50px", paddingRight: "50px" }
            //     : {}
            // }
          >
            {/* {title ? (
              <PageTitle breadcrumbs={breadcrumbs}>{title}</PageTitle>
            ) : null} */}
            {children}
          </div>
        </div>
      </div>
    </Container>
  );
}

RawLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

RawLayout.defaultProps = {
  className: "",
  children: null
};

const Layout = styled(memo(RawLayout))`
  display: flex;
  background: rgb(247, 247, 247);
  .rightSidePage {
    width: 100%;
    transition-duration: 0.5s;
    /* max-width: calc(100% - 50px); */
  }

  .scrollBox {
    /* flex-basis: 100%; */
    position: relative;
    height: calc(100vh - 55px); // 55px = header
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    /* background: ${palette.lightGray}; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: flex-start; */
  }

  .contentBox {
    position: relative;
    display: block;
    /* padding: 60px 90px 100px 90px; */
    padding: 0px 33px 50px 33px;
    color: ${palette.black};
    transition-property: padding;
    transition-duration: 0.7s;
    /* max-width: 1600px; */
    /* flex-basis: 100%; */
    width: 100%;
  }

  section {
    margin: 20px 0;
    padding: 15px 25px 5px 25px;
    /* background: white; */
    background: rgba(255, 255, 255, 0.8);
    /* border: 1px solid ${palette.gray}; */
    border-radius: 10px;
  }

  .box {
    padding: 15px 25px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .inlineBlock {
    display: inline-block;
    margin-right: 40px;
  }
`;

export default memo(Layout);

import { css } from 'styled-components';
import { palette } from '../palette';

export default css`
  /* background: ${palette.lightGray}; */
  margin: 0.8rem 0rem 0.8rem 0;
  padding: 0.7rem 1.5rem 0.7rem 1.5rem;
  border: 0;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize / 10}rem` : '1.4rem')};
  font-weight: 400;
  border-radius: 5px;
  color: ${palette.black};
  vertical-align: top;
  border: transparent 1px solid;
  transition-duration: 0.3s;

  background: white;
  border: 1px solid #aaa;

  ${(props) => props.spacebetween && `
      text-align: right;
  `}

  ${(props) => props.isblock
    && `
      width: 100%;
      margin-left: 0;
      margin-right: 0;
  `}

  ${(props) => props.iserror
    && `
      border: solid 1px ${palette.warningRed};
      background-color: ${palette.pink};
  `}

  ${(props) => props.disabled
    && `
        background: transparent;
        // background: ${palette.lightGray};
        cursor: text;
        color: ${palette.darkestGray};
        // border: 1px solid #999;
        border: transparent 1px solid;
        padding: 0;

        // iOS
        opacity: 1;
  `}

  ${(props) => props.transparent
    && `
      background: transparent;
      border-color: transparent;
  `}

  &:focus-visible {
    /* border: 0; */
    border-color: transparent;
    outline: 0;
    background-color: ${palette.lightBlue};
    ${(props) => props.transparent
      && `
        background: transparent;
    `}
  }

  ${(props) => props.isloading
    && `
      color: transparent;
      pointer-events: none;
      background: ${palette.lightGray};
      border-color: transparent;
  `}

  /* transparent one doesn't need to show when loading */
  ${(props) => props.transparent
    && props.isloading
    && `
      opacity: 0;
  `}

  /* &:disabled {
    -webkit-transition-duration: 1s;
    -webkit-text-fill-color: #999;
    ${(props) => props.isloading
    && `
      -webkit-text-fill-color: white;
    `}
  } */

  &:invalid {
    color: ${palette.darkGray};
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${palette.darkGray};
    opacity: 1; /* Firefox */
    ${(props) => props.isloading
      && `
      color: transparent;
    `}
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${palette.darkGray};
    ${(props) => props.isloading
      && `
      color: transparent;
    `}
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${palette.darkGray};
    ${(props) => props.isloading
      && `
      color: transparent;
    `}
  }
`;

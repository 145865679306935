import React from 'react';
import Home from 'pages/Home';
import VoucherSearch from 'pages/voucher/search';
import VoucherEach from 'pages/voucher/each';
// import MerchantInformation from 'pages/merchant/Information';
// import MerchantIntro from 'pages/merchant/Intro';
// import MerchantPhoto from 'pages/merchant/Photo';
// import EditPassword from 'pages/EditPassword';
// import EditAccount from 'pages/EditAccount';
// import FinancialTransactions from 'pages/financial/Transactions';
// import FinancialCharges from 'pages/financial/Charges';
// import FinancialContact from 'pages/financial/Contact';
// import Notifications from 'pages/Notifications';

const publicRoutes = [
  {
    path: '/',
    // component: <Home />,
    component: <VoucherSearch />,
    exact: true,
    role: 'user',
    backUrl: '/login',
  },
  {
    path: '/voucher/search',
    component: <VoucherSearch />,
    exact: true,
    role: 'user',
    backUrl: '/login',
  },
  {
    path: '/voucher/:id',
    component: <VoucherEach />,
    exact: true,
    role: 'user',
    backUrl: '/login',
  },
];

export default publicRoutes;

/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect, useState } from 'react';
import { useFormikContext, Field } from 'formik';
import styled from 'styled-components';
// import { palette } from 'style/palette';
import inputStyle from '../../style/form/input';
import useRequiredValidate from '../../hooks/useRequiredValidate';
import InputContainer from './InputContainer';

function Input({
  // basic props
  name,
  title,
  placeholder,
  // important state
  required, // for title and formik
  disabled, // let user not able to edit
  showError, // show error style
  isloading,
  isblock, // full of width
  isSelect, // note that: "as: select" cannot be as prop
  // addtional adjustment
  size = '', // default length for input width
  children, // for select to contain options
  decoration, // for password to contain the eye
  spacebetween, // for adjust flex
  // title props
  question, // for title
  hideStarSign, // for title
  lineUp, // for title
  withoutParent, // for title
  // others
  className,
  ...inputProps // contains: value, maxLength......
  // styled component
  // transparent,
  // isblock,
  // fontSize,
}) {
  const { errors, touched } = useFormikContext();
  const [iserror, setIserror] = useState(false);

  useEffect(() => {
    // two ways to cause error: pass showError props || there are really errors
    if ((errors[name] && touched[name]) || showError) setIserror(true);
    else setIserror(false);
  }, [errors, touched, showError, name]);

  return (
    <InputContainer
      name={name} // for errorMessage
      required={required}
      question={question}
      hideStarSign={hideStarSign}
      title={title}
      lineUp={lineUp}
      isblock={isblock}
      withoutParent={withoutParent}
      spacebetween={spacebetween}
    >
      <StyledInput
        iserror={iserror}
        className={className}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        title={title}
        required={required}
        isSelect={isSelect}
        size={size}
        isloading={isloading}
        isblock={isblock}
        spacebetween={spacebetween}
        {...inputProps}
      >
        {children}
      </StyledInput>
      {decoration}
    </InputContainer>
  );
}

function InputItSelf({
  className,
  iserror,
  name,
  disabled,
  placeholder,
  title,
  required,
  isSelect,
  size,
  children,
  ...inputProps
}) {
  const { values } = useFormikContext();
  const requiredValidate = useRequiredValidate([values[name]]);
  return (
    <Field
      className={className}
      name={name}
      disabled={disabled}
      placeholder={disabled ? '' : placeholder || (title ? `請輸入${title}` : '')}
      validate={required ? requiredValidate : undefined}
      // note that 'isRequired' is validated by feild level
      size={!isSelect ? size : 1}
      as={isSelect && 'select'}
      {...inputProps}
    >
      {children}
    </Field>
  );
}

const StyledInput = styled(memo(InputItSelf))`
  ${inputStyle}
`;

export default memo(Input);

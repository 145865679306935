import React from 'react';
import styled from 'styled-components';
import { palette } from '../../style/palette';

function RawSectionTitle({
  remark,
  // intro,
  className,
  children,
}) {
  return (
    <h2 className={className}>
      {children}
      {remark ? <span className="remark">{remark}</span> : null}
      {/* {intro ? <span className="intro">{intro}</span> : null} */}
    </h2>
  );
}

const SectionTitle = styled(RawSectionTitle)`
  position: relative;
  font-weight: 400;
  font-size: 2rem;
  margin-bottom: 2.8rem;
  width: calc(100% + 5.6rem);
  padding: 2rem 2.8rem;
  left: -2.8rem;
  margin-top: -0.5rem;
  /* background-image: linear-gradient(to bottom, #fff 85%, #dedede); */
  background-image: linear-gradient(to bottom, white 85%, white 95%, #ff974a);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  /* cursor: pointer; */

  .remark {
    color: ${palette.darkGray};
    margin-left: 0.5rem;
  }

  /* .intro {
    color: ${palette.darkerGray};
    display: block;
    font-size: 1.6rem;
    margin-top: 2rem;
    font-weight: 300;
  } */
`;

export default SectionTitle;

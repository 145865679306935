/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import Calendar from "react-calendar";
import { palette } from "style/palette";
import Input from ".";
import MockInput from "./InputMock";
import "react-calendar/dist/Calendar.css";

function RawDateInput({
  className,
  name,
  fontSize,
  isBlock,
  lineUp = false,
  hideStarSign,
  ...inputProps
}) {
  const { values, errors } = useFormikContext();
  const [controlDate, setControlDate] = useState(); // use js Date format
  useEffect(() => {
    // set default
    if (values[name]) setControlDate([new Date(values[name][0]), new Date(values[name][1])]);
  }, []);
  // for input displaying
  const displayDateFormat = (dateValue) =>
    dateValue
      ? `${dateValue[0].getFullYear()} - ${
          dateValue[0].getMonth() + 1
        } - ${dateValue[0].getDate()} 到 ${dateValue[1].getFullYear()} - ${
          dateValue[1].getMonth() + 1
        } - ${dateValue[1].getDate()}`
      : undefined;
  // for sending to backend
  const formalDateFormat = (dateValue) => {
    if (!dateValue) return undefined;
    const dateRange = [];
    dateRange.push(
      `${dateValue[0].getFullYear()}/${
        dateValue[0].getMonth() + 1
      }/${dateValue[0].getDate()}`
    );
    dateRange.push(
      `${dateValue[1].getFullYear()}/${
        dateValue[1].getMonth() + 1
      }/${dateValue[1].getDate()}`
    );

    return dateRange;
  };
  const handleCalendarChange = (dateValue) => {
    setControlDate(dateValue);
    values[name] = formalDateFormat(dateValue);
    // values[name] = dateValue;
  };

  const [showErrorForMockInput, setErrorForMockInput] = useState(false);
  useEffect(() => {
    if (errors[name]) setErrorForMockInput(true);
    else setErrorForMockInput(false);
  }, [errors]);
  return (
    <>
      <div className={className}>
        <Input
          name={name}
          hideStarSign={hideStarSign}
          {...inputProps}
          readOnly
          style={{opacity: 0}}
        />
        <MockInput
          // this is a container to show
          className="exhibitMockInput"
          {...inputProps}
          readOnly
          iserror={showErrorForMockInput}
          value={displayDateFormat(controlDate)}
        />
        <div className="calendar">
          <Calendar
            onChange={handleCalendarChange}
            value={controlDate}
            minDetail="year"
            maxDate={new Date()}
            formatDay={(locale, date) => date.getDate()}
            selectRange
          />
        </div>
      </div>
      {lineUp ? <br /> : null}
    </>
  );
}

const DateInput = styled(RawDateInput)`
  position: relative;
  display: inline-block;
  vertical-align: bottom;

  .exhibitMockInput {
    position: absolute;
    /* left: 6.6rem; // temp */
    right: -5rem;
    top: 0;
  }

  .calendar {
    transition-duration: 0.3s;
    pointer-events: none;
    opacity: 0;
    /* position: absolute; */
    position: fixed;
    /* left: 0px; */
    /* top: 6rem; */
    /* margin-right: -350px; */
    /* margin-top: 75px; */
    z-index: 999;
    /* top: 0; */
    .react-calendar {
      border-radius: 10px;
      padding: 10px;

      // patch for react-calendar
      .react-calendar__tile--now {
        background: ${palette.yellow};
      }
      .react-calendar__tile--active {
        background: ${palette.salmon};
      }
      .react-calendar__tile:disabled {
        background-color: transparent;
        opacity: 0.3;
      }
      .react-calendar__navigation button:disabled {
        background-color: transparent;
        opacity: 0.3;
      }
    }
  }
  &:hover {
    .calendar {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

export default DateInput;

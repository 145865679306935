import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

function RawContainer({ className, children }) {
  return (
    <div className={className}>
      {children}
    </div>
  );
}

RawContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

RawContainer.defaultProps = {
  className: '',
  children: null,
};

const Container = styled(memo(RawContainer))`
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    @media screen and (max-width: 1280px) {
        width: 1280px;
    }
`;

export default memo(Container);

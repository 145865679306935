/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Checkbox from "components/input/CheckboxMock";
import FeesListInTable from "components/voucher/FeesListInTable";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import styled from "styled-components";
// import { palette } from '../../style/palette';

const RawTableRow = forwardRef(
  (
    {
      object,
      order,
      checkbox,
      onClickCheckbox,
      allChecked,
      customColumnBefore,
      customColumnAfter,
      className
    },
    ref
  ) => {
    const [feesAreCollapsed, setFeesCollapsed] = useState(true);
    const [checked, setChecked] = useState(false);
    useImperativeHandle(ref, () => ({
      check() {
        setChecked(true);
      },
      uncheck() {
        setChecked(false);
      },
      collapseFeeList() {
        setFeesCollapsed(true);
      }
    }));

    return (
      <>
        <div
          className={`${className} rTableRow`}
          onClick={() => setFeesCollapsed(!feesAreCollapsed)}
        >
          {checkbox && (
            <div className="rTableCell" style={{ padding: "0", width: "0px" }}>
              <Checkbox
                checked={checked || allChecked}
                onChange={(e) => setChecked(e.target.checked)}
                onClick={onClickCheckbox}
              />
            </div>
          )}
          {customColumnBefore ? (
            <div
              className="rTableCell"
              style={{ padding: "5px", display: "flex" }}
            >
              {customColumnBefore(object)}
            </div>
          ) : null}
          {object &&
            order.map((item) => (
              <div
                key={item.key || item.title}
                className={`${item.nowrap ? "nowrap " : ""}rTableCell`}
              >
                {!item.format ? object[item.key] : item.format(object)}
              </div>
            ))}
          {customColumnAfter && !object.cancelCustomColumn ? (
            <div className="rTableCell" style={{ padding: 0, width: "65px" }}>
              {customColumnAfter(object)}
            </div>
          ) : null}
        </div>
        {/* customize for fees */} {/* temp */}
        {object.fees ? (
          <FeesListInTable
            fees={object.fees}
            order={[
              {
                key: "room_code",
                title: "房代碼"
              },
              {
                key: "contract_id",
                title: "合約編號"
              },
              {
                key: "subject",
                title: "科目"
              },
              {
                key: "amount",
                title: "金額"
              },
              {
                key: "atr_tax",
                title: "稅別"
              },
              {
                key: "atr_certificate",
                title: "憑證別"
              },
              {
                key: "atr_fee_type",
                title: "收付條件"
              },
              {
                key: "due_time",
                title: "應收/代收日期",
                format: (item) => item.due_time.split("T")[0]
              },
              {
                key: "atr_landlord_is_legal",
                title: "房東人格別"
              },
              {
                key: "atr_tenant_is_legal",
                title: "房客人格別"
              },
              {
                key: "atr_ar_info",
                title: "應收對象"
              },
              {
                key: "atr_ap_info",
                title: "應付對象"
              },
              {
                key: "exe_time",
                title: "預計收付日",
                format: (item) => item.exe_time.split("T")[0]
              }
            ]}
            collapsed={feesAreCollapsed}
          />
        ) : null}
      </>
    );
  }
);

const TableRow = styled(RawTableRow)`
  ${(props) =>
    props.pointer &&
    `
cursor: pointer;
`}

  .collapsedBtn {
    margin: 0 5px;
  }
`;

export default TableRow;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import Layout from "components/FormLayout";
import Button from "components/button";
import { palette } from "../style/palette";

function RawHome({ className }) {
  return (
    <Layout breadcrumbs={false} title="首頁">
      <div className={className}>
        <br />
        <Button to="/voucher/search">匯入單查詢</Button>
        {/* <Button to="/voucher/id">單一單別範例</Button> */}
      </div>
    </Layout>
  );
}

const Home = styled(RawHome)`
  .upperArea {
    position: absolute;
    width: 96.5%;
    /* height: 105px; */
    display: flex;
  }
  .filter {
    /* position: absolute; */
    position: relative;
    overflow: hidden;
    height: 183px;
    transition: 0.5s;

    /* temp */
    /* background-color: #fff; */
    width: 90%;

    /* &:hover {
      height: 520px;
    } */
  }
  .info {
    /* margin-top: -3rem; */
    max-height: 183px;
  }

  h2 {
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 6rem;
  }

  .introBox {
    min-height: 40rem;
  }

  .intros {
    margin-top: 4rem;
    .intro {
      position: relative;
      min-width: 23.5rem;
      margin-bottom: 3rem;
      .link {
        display: inline-block;
        color: ${palette.salmon};
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
      .date {
        font-size: 1.2rem;
      }
      .downloadBtn {
        position: absolute;
        display: flex;
        align-items: center;
        right: 0;
        /* bottom: 0; */
        border: 0.1rem solid #d7dbec;
        color: #5a607f;
        padding: 0.1rem 1.2rem;
        height: 2.4rem;
        font-size: 1.4rem;
        border-radius: 2rem;
        margin-top: -1rem;
        cursor: pointer;

        img {
          width: 2.4rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
`;

export default Home;

import React from "react";
import { palette } from "style/palette";
import styled from "styled-components";

function RawFeesListInTable({ fees, order, collapsed, className }) {
  return (
    <div className={`${className} rTableRow`}>
      {!collapsed && (
        <td className="rTableCell feeTableCell" colSpan={10}>
          {/* colSpan is tem */}
          <div className="fees">
            <div className="rTable">
              <div className="rTableHeading">
                {order?.map((item) => (
                  <div key={item.key} className="rTableHead">
                    {item.title}
                  </div>
                ))}
              </div>
              {fees?.map((fee) => (
                <div className="rTableRow" key={fee.fee_id}>
                  {order?.map((item) => (
                    <div key={item.key} className="rTableCell">
                      {!item.format ? fee[item.key] : item.format(fee)}
                    </div>
                  ))}
                  {/* {Object.keys(fee).map((key) => (
                  <div className="rTableCell" key={key}>
                    {fee[key]}
                  </div>
                ))} */}
                </div>
              ))}
              {fees.length === 0 ? <div style={{margin: "1rem 2rem"}}>沒有任何資料</div> : null}
            </div>
          </div>
        </td>
      )}
    </div>
  );
}
const FeesListInTable = styled(RawFeesListInTable)`
  /* padding: 0; */
  ${(props) =>
    props.collapsed &&
    `
    border-width: 0 !important;
  `}
  .feeTableCell {
    padding: 0 !important;
  }
  .fees {
    padding-left: 5rem;
    width: 100%;
    max-height: 34rem;
    overflow: scroll;
    transition-duration: 0.5s;
    max-width: calc(100vw - 116px);
    /* max-width: 1324px; */
    padding: 0 !important;
    overflow: scroll;
    background-color: rgba(255, 255, 255, 0.5);
    left: 0;
    top: 0;
    border: 2px solid ${palette.gray};
    /* margin-top: 5rem; */
    /* display: none; */
    .rTableHeading,
    .rTableRow {
      border-color: ${palette.gray};
      cursor: auto;
      /* border-width: 1px; */
    }
    .rTable {
      margin: 0.5rem 0rem;
    }

    ${(props) =>
      props.collapsed &&
      `
      height: 0;
      border-width: 0;
    `}
  }
`;

export default FeesListInTable;

export default (values, errorMessage) => () => {
  let error;
  for (const value of values) {
    if (!value || value === '') {
      error = errorMessage || '此為必填項目';
      break;
    }
  }
  return error;
};
